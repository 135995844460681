// working
// Core
//
import { Controller } from 'stimulus';

// Plugins
//
import Sortable from 'sortablejs/modular/sortable.core.esm.js';
import { hasClass, addClass, removeClass } from '../../helpers/dom-helper';
import PubSub from 'pubsub-js';

export default class extends Controller {
  static targets = ['list1', 'list2'];

  declare list1Target: HTMLInputElement;
  declare list2Target: HTMLInputElement;
  declare sortable1: Sortable;
  declare sortable2: Sortable;

  connect() {
    this.sortable1 = Sortable.create(this.list1Target, {
      group: 'foo',
      onEnd: this.end.bind(this)
    })

    this.sortable2 = Sortable.create(this.list2Target, {
      group: 'foo',
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    const elementMoved = event.item;
    const hideableContent = elementMoved.getElementsByClassName('hideable')[0];
    
    if (Array.from(this.list1Target.children).includes(elementMoved)) {
      addClass(hideableContent, '-state-hidden');
      PubSub.publish('permission-' + elementMoved.dataset.subjectClass, {
        checked: false 
      });
    } else {
      removeClass(hideableContent, '-state-hidden');
    }
  }
}