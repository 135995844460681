// Working
import CableReady from 'cable_ready'
import { Controller } from 'stimulus'

// Helpers
//
// @ts-ignore
import Interaction from '../plugins/interaction.ts';

export default class extends Controller {
  static values = { sggId: String }
  
  declare channel: any

  connect () {
    // @ts-ignore
    this.channel = this.application.consumer.subscriptions.create({ channel: 'JournalChannel', sgg_id: this.sggIdValue } , {     
      received (data) {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    })
  }
  
  disconnect () {
    this.channel.unsubscribe()
  }

  closeModal(){
    new Interaction().close();
  }
}