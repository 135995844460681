// not used
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import _templateSettings from 'lodash/templateSettings';
import _template from 'lodash/template';
import _get from 'lodash/get';

// Plugins
//
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = ['assetsContainer', 'qcContainer'];

  declare assetsContainerTarget: any;
  declare qcContainerTargets: any[];

  connect() {
    _templateSettings.interpolate = /\{\{\=(.+?)\}\}/g;
    _templateSettings.evaluate = /\{\{(.+?)\}\}/g;

    this.get();
  }

  public async get() {
    const response: AxiosResponse = await axios.get(this.data.get('url'));

    if (response) {
      this.assetsContainerTarget.innerHTML = response.data;

      const url: string = JSON.parse(response.headers.assessments).url,
            assets: string[] = response.headers.assets.split(',');

      assets.map((uuid: string) => uuid.trim()).forEach(async (uuid: string) => {
        const target: HTMLElement = document.getElementById(`qc-${ uuid }`),
              qcResponse: AxiosResponse = await axios.get(url.replace('@uuid', uuid), {
                headers: {
                  token: JSON.parse(response.headers.assessments).token
                }
              });

        if (qcResponse.data.success) {
          const qc: any = qcResponse.data.qc,
                template: any = _template(document.getElementById(`template-${ uuid }`).innerHTML);

          target.innerHTML = template({
            qc: qc != null,
            status: _get(qc, 'status'),
            completed_at: _get(qc, 'completed_at'),
            user_decision: _get(qc, 'user_decision'),
            supervisor_decision: _get(qc, 'supervisor_decision')
          });
        } else {
          // TODO: Error Handling
        }
      })
    } else {
      // TODO: Error Handling
    }
  }
}