// working
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import { addClass, removeClass, hasClass } from '../../helpers/dom-helper';
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = ['memberships'];

  declare membershipsTarget: HTMLScriptElement;

  public async new(){
    const request: AxiosResponse<any> = await axios.get('/memberships/new.json', {
    });

    if (request.status === 200) {
      this.membershipsTarget.insertAdjacentHTML('beforeend', request.data)
    }
  }
}