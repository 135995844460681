// not used
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import _templateSettings from 'lodash/templateSettings';
import _template from 'lodash/template';
import _debounce from 'lodash/debounce';

// Plugins
//
import axios, { AxiosResponse } from 'axios';
// @ts-ignore
import Interaction from '../../plugins/interaction.ts';

export default class extends Controller {
  static targets = ['code', 'feedback', 'loading', 'success', 'error'];

  declare codeTarget: any;
  declare feedbackTarget: any;
  declare loadingTarget: any;
  // successTarget: any;
  // errorTarget: any;

  debounce: any;

  connect() {
    _templateSettings.interpolate = /\{\{\=(.+?)\}\}/g;
    _templateSettings.evaluate = /\{\{(.+?)\}\}/g;

    this.feedbackTarget.innerText = '';

    this.debounce = _debounce((query: string) => {
      this.search(query)
    }, 750, {
      maxWait: 1000
    });
  }

  async search(event: any) {
    const template: any = _template(document.getElementById('responseBodyTemplate').innerHTML),
          response: AxiosResponse<any> = await axios.post(this.data.get('url'), {
            code: this.codeTarget.value
          });

    const status: boolean = response.data.success && response.data.available;

    this.loadingTarget.setAttribute('data-hidden', true);
    // this.successTarget.setAttribute('data-hidden', !status);
    // this.errorTarget.setAttribute('data-hidden', status);

    document.getElementById('responseBody').innerHTML = template({
      items: response.data,
      totalResults: response.data.length,
    });
  }

  check(event) {
    const query: string = this.codeTarget.value;

    if (query.length >= 3) {
      this.loadingTarget.setAttribute('data-hidden', false);
      // this.successTarget.setAttribute('data-hidden', true);
      // this.errorTarget.setAttribute('data-hidden', true);

      this.debounce(query);
    } else {
      this.feedbackTarget.innerText = ` ( ${ 3 - query.length } more characters please )`;
    }
  }

  async assign(event: any) {
    event.preventDefault();

    const response: AxiosResponse<any> = await axios.post(event.target.href, {
      item: {
        resourceable_id: event.target.dataset.uuid
      }
    });

    // if (response) {
      new Interaction().close();
    // } else {

    // }
  }
}