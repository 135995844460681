// at least partly working, have only been able to hit the show method
// Helpers
//
import { hasClass, addClass, removeClass } from '../helpers/dom-helper';

// Plugins
//
import axios, { AxiosResponse } from 'axios';
import TingleModal from 'tingle.js';
// @ts-ignore
import Tibby from './tibby.ts';
import SlimSelect from 'slim-select';
import Litepicker from 'litepicker';

declare var ReactRailsUJS;

// var componentRequireContext = require.context("components", true);
// var ReactRailsUJS = require("react_ujs");
// ReactRailsUJS.useContext(componentRequireContext);

export default class Actions {
  animations: any = {
    top: {
      in: 'slideInDown',
      out: 'slideOutUp'
    },
    left: {
      in: 'slideInLeft',
      out: 'slideOutLeft'
    },
    bottom: {
      in: 'slideInUp',
      out: 'slideOutDown'
    },
    right: {
      in: 'slideInRight',
      out: 'slideOutRight'
    }
  }

  public async show(event: any) {
    event.preventDefault();

    const target: any = event.currentTarget;
    let url: any;

    if (target.hasAttribute('href')) {
      url = target.href;
    } else {
      url = target.attributes.href.value;
    }

    const response: AxiosResponse<any> = await axios.get(url);

    if (response.status === 200) {
      this.popout(target, { innerHTML: response.data }, true);
    } else {
      console.error(response);
    }
  }

  public edit(event: any) {
    this.modal(event);
  }

  public new(event: any) {
    this.modal(event);
  }

  public async destroy(event: any) {
  }

  public async modal(event: any) {
    event.preventDefault();

    const target: any = event.currentTarget,
          url: string = target.href,
          response: AxiosResponse<any> = await axios.get(url);

    if (response) {
      const modal: TingleModal = new TingleModal.modal({
        beforeOpen: () => {
          // document.querySelectorAll('.select').forEach((selector: HTMLSelectElement) => {
          //   // new SlimSelect({
          //     select: selector
          //   });
          // })

          modal.checkOverflow();
        },

        beforeClose: () => {
          return true;
        },

        onClose: async () => {
          await modal.destroy();
        }
      });

      await modal.setContent(response.data);
      await modal.open();
    }
  }

  public async add(event: any) {}

  public async remove(event: any) {}

  public async help(event: any) {}

  public async replace(event: any) {}

  // TODO: Because the Modal is part of Tingle.JS library, we'll treat InteractionClass
  // as the popout class and everything else is just an addon that will be probably be
  // moved into its own class which will extend from InteractionClass
  //
  public async popout(event: any, element?: any, remote: boolean = false) {
    if (event && !element) {
      event.preventDefault();

      const target: any      = event.currentTarget,
            hash: number     = target.href.indexOf('#'),
            selector: string = target.href.substring(hash);

      element = document.querySelectorAll(selector)[0];
    }

    let options: any = element.dataset;

    if (remote) { options = event.dataset; }

    this.element.container.innerHTML = element.innerHTML;
    this.element.container.dataset.position = options.position;

    if (options.backdrop == 'true') {
      addClass(document.body, 'popout-open');
      removeClass(this.element.backdrop, '-state-hidden');
      addClass(this.element.backdrop, 'fadeIn');
    }

    removeClass(this.element.popout, '-state-hidden');
    addClass(this.element.popout, 'fadeIn');

    removeClass(this.element.container, '-state-hidden');
    addClass(this.element.container, this.animations[options.position.replace('-', '')].in);
    addClass(this.element.container, options.position);

    ReactRailsUJS.mountComponents();
    new Tibby();
    
    document.querySelectorAll('.select').forEach((selector: HTMLSelectElement) => {
      new SlimSelect({
        select: selector
      });
    })

    document.querySelectorAll('.form-control.date.-range').forEach((selector: any) => {
      new Litepicker({
        element: selector,
        selectForward: true,
        singleMode: false,
        disableWeekends: true,
        mobileFriendly: true
      });
    });

    this.element.backdrop.addEventListener('click', (event: any) => {
      this.close();
      // Array.from(document.querySelectorAll('.onChangeSubmit')).forEach((form: any) => {
      //   form.addEventListener('change', () => {
      //     form.submit()
      //   })
      // });
    }, false);
  }

  public open(event: any, content?: any) {
    this.popout(event, content);
  }

  public close() {
    const position: string = this.element.container.dataset.position,
          direction: string = position.replace('-', '');

    removeClass(document.body, 'popout-open');

    // Start the 'out' animation
    //
    addClass(this.element.popout, 'fadeOut');
    addClass(this.element.backdrop, 'fadeOut');
    addClass(this.element.container, this.animations[direction].out);

    // Clean up the classList
    //
    removeClass(this.element.container, this.animations[direction].in);

    // Create a delay to give the 'out' animation to complete, after which we clean up the
    // remaining classList
    //
    setTimeout(() => {
      addClass(this.element.popout, '-state-hidden');
      removeClass(this.element.popout, 'fadeIn');
      removeClass(this.element.popout, 'fadeOut');

      addClass(this.element.backdrop, '-state-hidden');
      removeClass(this.element.backdrop, 'fadeIn');
      removeClass(this.element.backdrop, 'fadeOut');

      addClass(this.element.container, '-state-hidden');
      removeClass(this.element.container, this.animations[direction].out);
      removeClass(this.element.container, position);

      this.element.container.innerHTML = '';
    }, 500);
  }

  get element() {
    const popout: HTMLElement = document.querySelector('.popout'),
          backdrop: HTMLElement = popout.querySelector('.-backdrop'),
          container: HTMLElement = popout.querySelector('.-container');
    
    return {
      popout: popout,
      backdrop: backdrop,
      container: container
    }
  }

  get isOpen(): boolean {
    return this.element.container.children.length > 0;
  }

  private parent(target: any): HTMLElement {
    return document.getElementById(target.dataset.parent);
  }
}