import React from 'react';
import PubSub from 'pubsub-js';


const COORDS = { 
  england: { long: '-0.827719351724839', lat: '52.4296397906823', normal: { fill: "hsl(117, 61%, 50%)", stroke: "#b30059" } },
  wales: { long: '-3.3460041660316', lat: '52.2475537265661' },
  scotland: { long: '-4.23532998018595', lat: '56.4858405730834' },
  northern_ireland: { long: '-6.65361479449271', lat: '54.6617893613778' },
  ireland: { long: '-7.7122', lat: '53.2357' },
  ireland_2: { long: '-7.0122', lat: '53.9357' },
  ireland_3: { long: '-8.2122', lat: '53.0357' },
}
const MAXBUBBLESIZE = 50;
const MINBUBBLESIZE = 10

class Heatmap extends React.Component {

  constructor(props) {
    super(props);

    let data = this.amalgamateData();

    this.state = { show: 'uk', ukData: data.ukData, irelandData: data.irelandData, smallestValue: data.smallestValue, largestValue: data.largestValue };
  }

  amalgamateData() {
    let smallestValue = 100000;
    let largestValue = 0;

    let ukData = []
    let irelandData = []

    for (let i = 0; i < this.props.data['uk'].length; i++) {
      let datum = this.props.data['uk'][i];

      let mapDatum = {}
      mapDatum['id'] = datum.name
      mapDatum['name'] = datum.label
      mapDatum['size'] = datum.value
      mapDatum['long'] = COORDS[datum.name].long
      mapDatum['lat'] = COORDS[datum.name].lat
      if (COORDS[datum.name].normal) {
        mapDatum['normal'] = COORDS[datum.name].normal;
      }

      smallestValue = Math.min(smallestValue, datum.value);
      largestValue = Math.max(largestValue, datum.value);

      ukData.push(mapDatum);
    }

    for (let i = 0; i < this.props.data['ireland'].length; i++) {
      let datum = this.props.data['ireland'][i];

      let mapDatum = {}
      mapDatum['id'] = datum.name
      mapDatum['name'] = datum.label
      mapDatum['size'] = datum.value
      mapDatum['long'] = COORDS[datum.name].long
      mapDatum['lat'] = COORDS[datum.name].lat

      smallestValue = Math.min(smallestValue, datum.value);
      largestValue = Math.max(largestValue, datum.value);

      irelandData.push(mapDatum);
    }


    for (let i = 0; i < ukData.length; i++) {
      let datum = ukData[i];


    }

    return { ukData: ukData, irelandData: irelandData, smallestValue: smallestValue, largestValue: largestValue }
  }

  componentDidMount() {
    let self = this;
    anychart.onDocumentReady(function () {
      self.ukMap(); 
      self.irelandMap();  
    });
  }

  calculateBubbleColour(value) {
    // This is used to essentially keep the colour brightness between 30% and 60%;
    let colourWindow = 30;
    let colourLowerBuffer = 30
    let ratio = (value - this.state.smallestValue) / (this.state.largestValue - this.state.smallestValue);

    let percentage = colourLowerBuffer + (colourWindow * ratio);

    "hsl(117, '61%', percentage + '%')"
  }

  ukMap() {
    // See https://docs.anychart.com/Maps/Map_Projections

    /*
      GBR-ENG  England  -0.827719351724839  52.2296397906823
      GBR-NIR  Northern Ireland  -6.95361479449271  54.6617893613778
      GBR-SCT  Scotland  -4.23532998018595  56.8858405730834
      GBR-WLS  Wales  -3.5460041660316  52.0475537265661
    */


    // data
    let data = this.state.ukData;

    // create map chart
    let map = anychart.map();

    // set geodata using https://cdn.anychart.com/geodata/2.0.0/countries/australia/australia.js
    map.geoData(anychart.maps['united_kingdom']);

    //create bubble series
    let series = map.bubble(data);

    // set max and min bubble sizes
    map.maxBubbleSize(MAXBUBBLESIZE);
    map.minBubbleSize(MINBUBBLESIZE);

    series.labels(true);

    series.labels().fontColor('black');
    series.labels().fontSize(12);
    series.labels().format("{%size}");
    series.labels().anchor('center');
    series.labels().position('center');

    // set container id for the chart
    map.container('heatmap-uk');
    // initiate chart drawing
    map.draw();
  }

  irelandMap() {
    // This sample uses 3rd party proj4 component
    // that makes it possible to set coordinates
    // for the points and labels on the map and
    // required for custom projections and grid labels formatting.
    // See https://docs.anychart.com/Maps/Map_Projections

    // data
    let data = this.state.irelandData;

    // create map chart
    let map = anychart.map();

    // set geodata using https://cdn.anychart.com/geodata/2.0.0/countries/australia/australia.js
    map.geoData(anychart.maps['ireland']);

    //create bubble series
    let series = map.bubble(data);

    /*

      maxValue = 142
      minValue = 10

      maxBubble = 50
      minBubble = 10

      diff = maxValue - minValue

      diffSize = maxBubble - minBubble

      valueSize = diffSize / diff

    */


    let ratio = (data[0].size - this.state.smallestValue) / (this.state.largestValue - this.state.smallestValue)

    if (isNaN(ratio) || !isFinite(ratio)) {
      ratio = 0.5;
    }

    let minBubbleSize = (MINBUBBLESIZE + (ratio * (MAXBUBBLESIZE - MINBUBBLESIZE)));

    // set max and min bubble sizes
    map.maxBubbleSize(MAXBUBBLESIZE);
    map.minBubbleSize(MINBUBBLESIZE);

    series.labels(true);

    series.labels().fontColor('black');
    series.labels().fontSize(14);
    series.labels().format("{%size}");
    series.labels().anchor('center');
    series.labels().position('center');

    // set container id for the chart
    map.container('heatmap-ireland');
    // initiate chart drawing
    map.draw();
  }

  switchMap(map) {
    this.setState({ show: map });
  }

  render() {
    return (
      <div>
        <span onClick={() => this.switchMap('uk')}>UK</span> | <span onClick={() => this.switchMap('ireland')}>Ireland</span>
        <div id="heatmap-uk" style={{display: this.state.show == 'uk' ? '' : 'none', width: "500px", height: "800px"}}></div>
        <div id="heatmap-ireland" style={{display: this.state.show == 'ireland' ? '' : 'none', width: "333px", height: "533px"}}></div>
      </div>
    );
  }



}

export default Heatmap;