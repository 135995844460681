// working
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import { DataTable } from "simple-datatables";

export default class extends Controller {
  static targets = [''];

  connect() {
    const table: HTMLTableElement = document.querySelector("#jobs");

    if (table) {
      new DataTable(table, {
        perPage: 100,
        perPageSelect: [50, 100, 150, 200],
        fixedHeight: true,
        fixedColumns: false,
        layout: {
          top: "{search}{info}{pager}{select}",
          bottom: ""
        }
      });
    }
  }
}