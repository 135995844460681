// working
// Core
// 
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [''];

  connect() { }

  public change(event: any) {
    console.log(event.currentTarget)

    window.location.href = `${ this.data.get('url') }/${ event.currentTarget.value }`;
  }
}