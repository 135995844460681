// working
import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = ['container', 'url']  
  
  declare urlValue: String;
  declare containerTarget: HTMLElement;
  declare sortable: Sortable;

  initialize () {
    this.end = this.end.bind(this)
  }

  connect () {
    this.sortable = new Sortable(this.containerTarget, {
      ...this.options
    })
  }

  disconnect () {
    this.sortable.destroy()
    this.sortable = undefined
  }

  public async end ({ item, newIndex }) {
    if (!item.dataset.sortableUrl) return

    const resourceName = item.dataset.sortableModel;

    const response: AxiosResponse<any> = await axios.patch(item.dataset.sortableUrl, { 
      [resourceName]: { row_order_position: newIndex }
    });
  }

  get options () {
    return {
      animation: 150,
      handle: '.sortable-handle',
      onEnd: this.end
    }
  }
}