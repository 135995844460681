// working
// Core
//
import { Controller } from 'stimulus';

// Plugins
//
import axios, { AxiosResponse } from 'axios';
import _templateSettings from 'lodash/templateSettings';
import _template from 'lodash/template';
import _debounce from 'lodash/debounce';

enum Resource {
  data = 'VehicleData',
  images = 'VehicleImageData'
}

export interface Vehicle {
  make: string,
  model: string,
  colour: string,
  width: number,
  length: number,
  weight: number,
  co2: number,
  fuel_type: string,
  logo: string,
  cover: string
}

export default class extends Controller {
  static targets = ['input', 'template', 'preview'];

  declare token: string;

  declare debounce: any;

  declare inputTarget: any;
  declare templateTarget: any;
  declare previewTarget: any;

  // TODO: Find out how to create an Interface which also acts as a class so
  // instead of doing an Interface and then initializing it like this, we can do
  // new Vehicle() instead!
  //
  vehicle: Vehicle = {
    make: '',
    model: '',
    colour: '',
    width: 0,
    length: 0,
    weight: 0,
    co2: 0,
    fuel_type: '',
    logo: '',
    cover: ''
  };

  connect() {
    _templateSettings.interpolate = /\{\{\=(.+?)\}\}/g;
    _templateSettings.evaluate = /\{\{(.+?)\}\}/g;

    this.token = 'e6f5029a-cec9-4fb7-b257-8a993575ac3a';

    this.debounce = _debounce((query: string) => { this.search(query) }, 750, {
      maxWait: 1000
    });
  }

  public fromVRN(event: MouseEvent) {
    const target: any = event.currentTarget,
          value: string = target.innerText;

    this.inputTarget.value = value;
    this.search(value);
  }

  public async search(event: any) {
    const query: string = (typeof(event) == 'string' ? event : event.currentTarget.value);

    await this.getVehicleData(query, Resource.data);
    await this.getVehicleImages(query, Resource.images);

    Object.keys(this.vehicle).forEach((key: string) => {
      const input: HTMLInputElement = document.querySelector(`input[name="vehicle[${ key }]"]`);

      if (input) { input.value = this.vehicle[key] }
    });

    const template: any = _template(this.templateTarget.innerHTML);
    this.previewTarget.innerHTML = template(this.vehicle);
  }

  private async getVehicleData(query, resource: Resource) {
    const request: AxiosResponse<any> = await axios.get(this.url(query, resource)),
          response: any = request.data.Response;

    if (response.StatusCode == 'Success') {
      const data: any = response.DataItems;

      this.vehicle.make      = data.ClassificationDetails.Dvla.Make;
      this.vehicle.model     = data.ClassificationDetails.Dvla.Model;
      this.vehicle.colour    = data.VehicleRegistration.Colour;
      this.vehicle.width     = data.TechnicalDetails.Dimensions.Width;
      this.vehicle.length    = data.TechnicalDetails.Dimensions.CarLength;
      this.vehicle.weight    = data.TechnicalDetails.Dimensions.GrossVehicleWeight;
      this.vehicle.co2       = data.TechnicalDetails.Performance.Co2;
      this.vehicle.fuel_type = data.VehicleRegistration.FuelType;
      this.vehicle.logo      = `https://cdn.vdicheck.com/badges/${ this.vehicle.make }.png`;

    } else { }
  }

  private async getVehicleImages(query, resource: Resource) {
    const request: AxiosResponse<any> = await axios.get(this.url(query, resource)),
          response: any = request.data.Response;

    if (response.StatusCode == 'Success') {
      this.vehicle.cover = response.DataItems.VehicleImages.ImageDetailsList[0].ImageUrl;

    } else { }
  }

  private url(vrn: string, resource: Resource): string {
    return `https://uk1.ukvehicledata.co.uk/api/datapackage/${ resource }?v=2&api_nullitems=1&auth_apikey=${ this.token }&user_tag=&key_VRM=${ vrn }`
  }
}