// technically works
// Core
//
import { Controller } from 'stimulus';

// Plugins
//
import axios, { AxiosResponse } from 'axios';
import _debounce from 'lodash/debounce';

export default class extends Controller {
  static targets = ['input', 'loader', 'success', 'error'];

  declare debounce: any;

  declare inputTarget: HTMLInputElement;

  declare loaderTarget: any;
  declare successTarget: any;
  declare errorTarget: any;

  connect() {
    this.debounce = _debounce((query: string) => {
      this.search(query)
    }, 750, {
      maxWait: 1000
    });
  }

  public check() {
    const query: string = this.inputTarget.value;

    if (query.length >= 3) {
      this.loaderTarget.setAttribute('data-hidden', false);
      this.successTarget.setAttribute('data-hidden', true);
      this.errorTarget.setAttribute('data-hidden', true);

      this.debounce(query);
    } else {
      console.log('Oops! Error');
    }
  }

  async search(query: string) {
    const response: AxiosResponse<any> = await axios.post(
      this.inputTarget.dataset.url, {
        query: query
      });

    const status: boolean = response.data.available;

    this.loaderTarget.setAttribute('data-hidden', true);
    this.successTarget.setAttribute('data-hidden', !status);
    this.errorTarget.setAttribute('data-hidden', status);
  }
}