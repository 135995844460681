// working
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import { hasClass, addClass, removeClass } from '../../helpers/dom-helper';

// Plugins
//
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = [];

  connect() { }

  public async complete(event: any) {
    const url: string = this.data.get('completeUrl'),
          response: AxiosResponse = await axios.post(url, {
            id: event.target.value
          });

    event.target.disabled = true;

    if (response.data.success) {
      window.location.reload();
    } else {
      event.target.disabled = false;
    }
  }
}