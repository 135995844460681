// not working, not working on develop
// no mapboxgl plugin
// Core
//
import { Controller } from 'stimulus';

// Plugins
//

declare var mapboxgl;

export default class extends Controller {
  static targets = ['map'];

  declare hasMapTarget: boolean;
  declare mapTarget: any;
  declare mapTargets: any[];

  connect() {
    mapboxgl.accessToken = 'pk.eyJ1Ijoic21hcnRlZGl0b3JzIiwiYSI6ImNrYmduNjEyYzE3NWQyem5vNjJzeXE0d3MifQ.MX3vn11muEy1l4dhNLUv2w';
    this.render();
  }

  private async render() {
    new mapboxgl.Map({
      container: this.mapTarget,
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 8,
      pitch: 50,
      bearing: 15,
      center: JSON.parse(this.data.get('coordinates'))
    });
  }

  disconnect() { }
}