// working
// Controller for the SlimSelect view component using Slim Select JS plugin
// Could be tidied up using Values instead of data attributes, but values
// stopped working half way through this and I can't get them to work again 

// Core
//
import { Controller } from 'stimulus';

// Plugins
//
import SlimSelect from 'slim-select';
import _debounce from 'lodash/debounce';
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {

  static targets = ['location', 'contact']

  declare locationTarget: any;
  declare contactTarget: any;
  declare limit: string;
  declare locationSelect: any;
  declare contactSelect: any;
  declare delayedSearch: any;
  declare delayedContactSearch: any;

  initialize(){
    this.delayedSearch = _debounce((query: string, callback: Function) => { this.search(query, callback) }, 500);
  }

  connect() {
    this.limit = '10';
    
    this.locationSelect = new SlimSelect({
      select: '#slim-location',
      searchingText: 'Searching...',
      ajax: (search, callback) => {     
        const options = this.delayedSearch(search, callback);
      }
    })

    this.contactSelect = new SlimSelect({
      select: `#slim-contact`,
      searchingText: 'Searching...'
    })
  }

  public async search(query: string, callback: Function) {
    const response: AxiosResponse<any> = await axios.get('/admin/locations', {
      params: {
        format: 'json',
        query: query,
        per_page: this.limit
      }
    });

    if(response.status === 200 && response.data) {
      let options = []
      for (let i = 0; i < response.data.length; i++) {
        options.push({ value: response.data[i].id, text: response.data[i]['name_with_address'] })
      }
      callback(options);
    }
  }

  setContacts(event) {
    var ids = this.selectedLocationIds(event);
    if(ids.length) {
      this.fetchContacts(ids);
      this.showContactsField(true);
    } else {
      this.contactSelect.setData({});
      this.showContactsField(false);
    }    
  }

  showContactsField(show: boolean) {
    this.contactTarget.style.display = show ? 'block' : 'none' 
  }

  public async fetchContacts(ids: String[]) {
    const response: AxiosResponse<any> = await axios.get('/admin/locations', {
      params: {
        format: 'json',
        location_ids: ids,
        per_page: this.limit
      }
    });

    if(response.status === 200 && response.data) {
      var contacts = this.contactOptions(response);
      this.contactSelect.setData(contacts);
    }
  }
  
  selectedLocationIds(event) {
    var options = event.target.selectedOptions;
    var ids: String[] = [];
    for (let i = 0; i < options.length; ++i) {
      ids.push(options[i].value)
    }
    return ids;
  }

  contactOptions(response){
    let contacts = []
    for (let i = 0; i < response.data.length; i++) {
      var location = response.data[i];
      for (let n = 0; n < location.contacts.length; n++) {
        var contact = location.contacts[n];
        contacts.push({ value: contact.id, text: contact.email })
      }
    }

    var uniqueContacts = contacts.filter(function(item, pos){
      return contacts.indexOf(item)== pos; 
    });

    return uniqueContacts;
  }
}