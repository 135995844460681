import React from 'react';
import TimelineSegment from './TimelineSegment';
import Select from 'react-select'

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

class Timeline extends React.Component {

  timeArray = [];

  constructor(props) {
    super(props);

    this.createTimes();
    this.prepareSelectOptions();

    // const html = props.notes || '';
    // const contentState = ContentState.createFromBlockArray(htmlToDraft(html).contentBlocks);
    // const editorState = EditorState.createWithContent(contentState);

    this.state = {
      // editorState: editorState,
      segments: props.segments || [],
      locations: props.locations || [],
      sggId: props.sgg_id || "",
      scheduledAt: props.scheduled_at || "",
      userId: props.user_id || "",
      uniformItems: props.uniformItems || [],
      template: props.template || false,
      title: props.title || "",
      clientId: props.client_id || "",
      notes: props.notes || "",
      scrollTarget: null
    }
    this.addRefsToSegments();
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
    this.setState({ notes: draftToHtml(convertToRaw(editorState.getCurrentContent()))  });
  };

  addRefsToSegments() {
    this.state.segments.forEach(function (segment) {
      let segmentRef = React.createRef();
      segment['segmentRef'] = segmentRef;
    })
  }

  componentDidUpdate() {
    if (this.state.scrollTarget && this.state.scrollTarget.current) {
      this.state.scrollTarget.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  createTimes() {
    if (this.timeArray.length == 0) {
      let minutes = [':00', ':30'];
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < minutes.length; j++) {
          let minute = minutes[j];
          let hour = i.toString();

          if (i < 10) {
            hour = "0" + hour;
          }

          this.timeArray.push(hour + minute);
        }
      }
    }
  }

  updateField(value, field) {
    let newState = { scrollTarget: null };
    newState[field] = value;

    this.setState(newState);
  }

  addSegment() {
    let segmentRef = React.createRef();
    let segmentsNotForDestroy = this.state.segments.filter(segment => !segment._destroy);
    let firstSegment = segmentsNotForDestroy[0];
    let lastSegment = segmentsNotForDestroy[segmentsNotForDestroy.length - 1];

    let operators = firstSegment ? firstSegment.operators.slice() : [];
    operators = operators.filter(operator => !operator._destroy).map(function (operator) {
      return { operator_id: operator.operator_id, quantity: operator.quantity };
    })

    let newTime = lastSegment ? this.incrementTimeByThirtyMinutes(lastSegment.time) : "00:00";

    // Added empty string for id to solve the "delete and add new segment" bug on existing timelines
    let newSegment = { id: "", segmentRef: segmentRef, _destroy: false, time: newTime, timelineTaskId: "", locationId: "", operators: operators, uniformItemIds: [], equipmentItemIds: [], duration: 0 };

    let segments = this.state.segments;

    segments.push(newSegment);
    this.sortSegments(segments);

    this.setState({ segments: segments, scrollTarget: segmentRef });
  }

  incrementTimeByThirtyMinutes(timeToIncrement) {
    let originalIndex = this.timeArray.indexOf(timeToIncrement);
    return this.timeArray[originalIndex + 1] || "00:00"
  }

  updateSegment(index, field, value) {
    let segment = this.state.segments[index];
    segment[field] = value;

    let segments = this.state.segments;

    segments[index] = segment;
    this.sortSegments(segments);

    // only scroll to the segment if we are altering the time, because that's the only time a segment will move
    let scrollTarget = field === "time" ? segment.segmentRef : null

    this.setState({ segments: segments, scrollTarget: scrollTarget });
  }

  removeSegment(index) {
    let segment = this.state.segments[index];

    if (segment.id) {
      this.updateSegment(index, '_destroy', "1");
    } else {
      let segments = this.state.segments;
      segments.splice(index, 1);

      this.setState({ segments: segments });
    }
  }

  duplicateOperators(index) {
    let operators = this.state.segments[index].operators.filter(operator => !operator._destroy);
    let clonedOperators = operators.map(function (operator) { return { id: "", operator_id: operator.operator_id, quantity: operator.quantity }; })
    let segments = this.state.segments
    segments.filter((segment, i) => i != index).forEach(function (segment) {
      segment.operators.forEach(function (operator, i) {
        operator.id ? segment.operators[i]["_destroy"] = "1" : delete segment.operators[i];
      })
      JSON.parse(JSON.stringify(clonedOperators)).forEach(function (operator) {segment.operators.push(operator)});
    })
    this.setState({ segments: segments });
  }

  sortSegments(segments, andSet = false) {
    segments = segments.sort((a, b) => (a._destroy - b._destroy) === 0 ? (a.time > b.time) ? 1 : (b.time > a.time ? -1 : 0) : a._destroy - b._destroy);
  }

  prepareSelectOptions() {
    this.selectOptions = { locationOptions: [], uniform: [] };

    for (let i = 0; i < this.props.availableBuildings.length; i++) {
      let location = this.props.availableBuildings[i];
      this.selectOptions.locationOptions.push({ value: location.id, label: location.name });
    }

    for (let i = 0; i < this.props.availableUniform.length; i++) {
      let uniform = this.props.availableUniform[i];
      this.selectOptions.uniform.push({ value: uniform.id, label: uniform.name });
    }
  }

  render() {
    const { editorState } = this.state;

    let noSegmentsContent = null;

    if (this.state.segments.length == 0) {
      noSegmentsContent = (
        <div className="row">
          <div className="cell-sm"></div>
          <div className="cell-sm-auto">
            <p className="-text-center">
              There are currently no tasks in this Timeline, click 'Add Task' to start creating your Timline.
            </p>
          </div>
          <div className="cell-sm"></div>
        </div>
      );
    }
    let formMethod = this.props.formMethod;
    if (formMethod == 'patch') {
      formMethod = 'post';
    }
    return (
      <div>
        <form method={formMethod} action={this.props.formUrl}>
          {this.props.formMethod == 'patch' &&
            <input type="hidden" name="_method" value="patch" />
          }
          <div className="row">
            <div className="cell-sm"></div>
            <div className="cell-sm-8">
              <div className="form">
                <div className="field compact">
                  <input type="hidden" name="timeline[client_id]" defaultValue={this.state.clientId} />
                  <div className="row">
                    <div className="cell-sm -margin-top_2">
                      <label>Notes</label>
                      {/*<Editor*/}
                      {/*  editorState={editorState}*/}
                      {/*  toolbar={{*/}
                      {/*    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'remove', 'history'],*/}
                      {/*    inline: { inDropdown: true },*/}
                      {/*    list: { inDropdown: true },*/}
                      {/*    textAlign: { inDropdown: true },*/}
                      {/*    history: { inDropdown: false },*/}
                      {/*  }}*/}
                      {/*  wrapperClassName="white"*/}
                      {/*  editorClassName="white form-control -margin-top_3 -padding_4"*/}
                      {/*  onEditorStateChange={this.onEditorStateChange}*/}
                      {/*/>*/}
                      {/*<textarea readOnly={true} rows="5" value={this.state.notes || ""} className="hidden form-control -margin-top_3 -padding_4" name="timeline[notes]" style={{ display: 'none', backgroundColor: 'white' }} />*/}
                      <textarea className="form-control -padding_3 -margin-top_3" style={{ backgroundColor: 'white' }} name="timeline[notes]" value={this.state.notes} onChange={(e) => this.updateField(e.target.value, 'notes')} rows="5" />
                    </div>
                  </div>

                  <div className="row -margin-top_2">
                    <div className="cell-sm-2 -padding-right_4">
                      <label>Schedule for</label>
                      <input type="date" className="form-control -padding_3 -margin-top_3" name="timeline[scheduled_at]" style={{ backgroundColor: 'white', padding: '6px 6px 5px' }} value={this.state.scheduledAt} onChange={(e) => this.updateField(e.target.value, 'scheduledAt')} />
                    </div>

                    <div className="cell-sm -padding-right_4">
                      <label>Title (optional)</label>
                      <input className="form-control -padding_3 -margin-top_3" style={{ backgroundColor: 'white' }} name="timeline[title]" value={this.state.title} onChange={(e) => this.updateField(e.target.value, 'title')} />
                    </div>

                    <div className="cell-sm-1">
                      <label>Template?</label>
                      <div className="align-center">
                        <input type="checkbox" className="align-middle -margin-top_2" name="timeline[template]" style={{transform: 'scale(1.3)'}} value={this.state.template} checked={this.state.template} onChange={(e) => this.updateField(e.target.checked, 'template')} />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="field compact cell-sm-12">
                      <Select className="-margin-top_3" name="timeline[uniform_ids][]" isMulti={true} options={this.selectOptions.uniform} value={this.state.uniformItems || []} onChange={(value) => this.updateField(value, 'uniformItems')} />
                      <label>Required Uniform & PPE</label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="cell-sm-1 -padding-right_4">
                      <label>Project</label>
                      <input className="form-control -padding_3 -margin-top_3" style={{ backgroundColor: 'white' }} name="timeline[sgg_id]" value={this.state.sggId} onChange={(e) => this.updateField(e.target.value, 'sggId')} />
                    </div>

                    <div className="cell-sm -padding-right_4">
                      <label>Buildings</label>
                      <Select className="-margin-top_3" name="timeline[location_ids][]" isMulti={true} options={this.selectOptions.locationOptions} value={this.state.locations || []} onChange={(value) => this.updateField(value, 'locations')} />
                    </div>

                    <div className="cell-sm-4 -padding-right_4">
                      <label>Site Lead</label>
                      <select className="form-control -padding_3 -margin-top_3" name="timeline[user_id]" value={this.state.userId || ""} onChange={(e) => this.updateField(e.target.value, 'userId')}>
                        {this.props.availableUsers.map(function (user, i) {
                          return <option key={i} value={user.id}>{user.name}</option>
                        }.bind(this))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cell-sm"></div>
          </div>

          <div className="vertical-timeline vertical-timeline--one-column-left">
            {this.state.segments.map(function (segment, i) {
              if (segment._destroy) {
                return (
                  <span key={i}>
                    <input type="hidden" name="timeline[timeline_segments_attributes][][id]" value={segment.id} />
                    <input type="hidden" name="timeline[timeline_segments_attributes][][_destroy]" value="1" />
                  </span>
                )
              } else {
                return <TimelineSegment index={i} key={i} {...segment} fieldPrefix="timeline[timeline_segments_attributes][]" timeArray={this.timeArray} availableTasks={this.props.availableTasks} availableSeverityLevels={this.props.availableSeverityLevels} availableOperators={this.props.availableOperators} availableUniform={this.props.availableUniform} availableEquipment={this.props.availableEquipment} availableTasks={this.props.availableTasks} availableLocations={this.props.availableLocations} updateCallback={this.updateSegment.bind(this)} removeCallback={this.removeSegment.bind(this)} duplicateOperatorsCallback={this.duplicateOperators.bind(this)} />
              }
            }.bind(this))}
            {noSegmentsContent}

            <div className="row">
              <div className="cell-sm"></div>
              <div className="cell-sm-auto -padding">
                <div className="btn -margin-top" onClick={this.addSegment.bind(this)}>Add Task</div>
              </div>
              <div className="cell-sm"></div>
            </div>
          </div>
          <div className="row -margin-y -padding-top" style={{ borderTop: '1px solid #eee' }}>
            <div className="cell-sm"></div>
            <div className="cell-sm-auto">
              <div className="row">
                <a className="btn -color -gray -darker -margin-right_3" href={this.props.cancelUrl}>Cancel</a>
                <input type="submit" value="Submit" className="btn" />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

}

export default Timeline;

