import React from 'react';

class CopyToClipboard extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      mouseOver: false
    }
  }

  copyText() {
    let dummy = document.createElement('textarea');
    dummy.value = this.props.text;
    document.body.appendChild(dummy);

    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    this.setState({ copied: true });
  }

  handleMouseEnter() {
    this.setState({ mouseOver: true });
  }

  handleMouseLeave() {
    this.setState({ mouseOver: false, copied: false });
  }

  render() {
    let popup = null;

    if (this.state.mouseOver) {
      let popupContent = 'Copy share URL to clipboard'
      if (this.state.copied) {
        popupContent = 'Share URL copied'
      }
      popup = (
        <span className="popup-tooltip">
          <span className="popup-tooltip-arrow"></span>
          <span className="popup-tooltip-inner">{popupContent}</span>
        </span>
      );
    }

    return (
      <span onMouseEnter={this.handleMouseEnter.bind(this)} onMouseLeave={this.handleMouseLeave.bind(this)} className="popup-tooltip-trigger -variation-empty -color -orange" title="Copy share link to clipboard" >
        <span className="-color -orange" onClick={this.copyText.bind(this)}>{this.props.label}</span>
        {popup}
      </span>
    );
  }

}

export default CopyToClipboard;
