// working
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import { addClass, removeClass } from '../../helpers/dom-helper';
import _debounce from 'lodash/debounce';
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = ['locationName', 'warning', 'savedLocationDiv', 'newLocationDiv'];

  declare locationNameTarget: HTMLInputElement;
  declare warningTarget: HTMLElement;
  declare savedLocationDivTarget: HTMLElement;
  declare newLocationDivTarget: HTMLElement;
  
  declare delayedCheck: any;
  declare shouldDisableSubmitButton: Boolean;

  initialize() {
    this.delayedCheck = _debounce((query: string) => { this.check(query) }, 1000);
    this.shouldDisableSubmitButton = false;
  }

  connect() { }

  private async check(event: any) {
    const response: AxiosResponse<any> = await axios.get('/admin/locations/find_by_name', {
      params: {
        format: 'json',
        query: this.locationNameTarget.value
      }
    });

    if (response.status === 200) {
      if (response.data) {
        this.locationNameTarget.style.border = "1px solid rgb(255, 0, 0)"
        this.disableSubmitButton(true);
        removeClass(this.warningTarget, '-state-hidden');
        this.shouldDisableSubmitButton = true;
      } else {
        this.locationNameTarget.style.border = "1px solid rgb(204, 204, 204)"
        this.disableSubmitButton(false);
        addClass(this.warningTarget, '-state-hidden');
      }
    }
  }

  disableSubmitButton(disable) {
    const submitButton = (document.getElementById('sgg-submit-button') as HTMLFormElement);
    submitButton.disabled = disable;
  }

  displayChosenLocationFields(event) {
    var showNewLocation = event.target.value === 'new' ? true : false;
    this.newLocationDivTarget.style.display = showNewLocation ? 'block' : 'none';
    this.savedLocationDivTarget.style.display = showNewLocation ? 'none' : 'block';
    this.enableFields(this.newLocationDivTarget, showNewLocation);
    this.enableFields(this.savedLocationDivTarget, !showNewLocation);
    this.disableSubmitButton(this.shouldDisableSubmitButton && showNewLocation);
  }

  enableFields(div, enable) {
    var inputs = div.getElementsByTagName('*');
    for(var i = 0; i < inputs.length; i++) {
      inputs[i].disabled = !enable;
    }
  }
}