import { Controller } from 'stimulus';

import * as Uppy from '@uppy/core';
import * as Dashboard from '@uppy/dashboard';
import * as ActiveStorageUpload from '@excid3/uppy-activestorage-upload';

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

export default class extends Controller {
  static targets = ['trigger', 'inputContainer', 'previewContainer'];
  static values = {
    id: Number,
    'field-attachment': String
  }

  declare triggerTarget: any;
  declare inputContainerTarget: any;
  declare previewContainerTarget: any;
  declare fieldAttachmentValue: string;

  declare hasInputContainerTarget: boolean;
  declare hasPreviewContainerTarget: boolean;

  uppy: any;
  direct_upload_url: string;

  connect() {
    this.direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute('content');

    this.uppy = Uppy({
      autoProceed: true,
      allowMultipleUploads: true,
      debug: false,
      restrictions: {
        maxFileSize: 104857600, // 100MB in bytes
        maxNumberOfFiles: 3,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*', '.svg']
      },
    });

    this.uppy.use(ActiveStorageUpload, {
      directUploadUrl: this.direct_upload_url
    })
  
    this.uppy.use(Dashboard, {
      trigger: this.triggerTarget,
      closeAfterFinish: true,
    })

    this.uppy.on('complete', (result) => {
      result.successful.forEach((file: any) => {
        this.appendUploadedFile(file, { with_preview: true });
      })
  
      this.uppy.reset()
    })
  }

  appendUploadedFile(file: any, options: { with_preview: boolean}) {
    const hiddenField: any = document.createElement('input')
  
          hiddenField.setAttribute('type', 'hidden');
          hiddenField.setAttribute('name', this.fieldAttachmentValue);
          hiddenField.setAttribute('data-pending-upload', true);
          hiddenField.setAttribute('value', file.response.signed_id);

    if (options.with_preview && this.hasPreviewContainerTarget) this.showPreview(file);
    if (this.hasInputContainerTarget) this.inputContainerTarget.appendChild(hiddenField);
  }

  showPreview(file: any) {
    const lastChild: HTMLDivElement = this.previewContainerTarget.lastElementChild
    const image: HTMLImageElement = new Image();

          image.src = file.preview;
          image.classList.add('w-16');
          image.classList.add('rounded-md');
          image.classList.add('t-cell-auto');

    this.previewContainerTarget.insertBefore(image, lastChild);
  }
}