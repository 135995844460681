// not working, not used
// no mapboxgl plugin
// Core
//
import { Controller } from 'stimulus';

// Plugins
//

declare var mapboxgl;

export default class extends Controller {
  static targets = ['map', 'source', 'coordinates', 'start', 'end', 'distance', 'duration'];

  declare token: any;

  declare mapTarget: any;
  declare sourceTarget: any;
  declare coordinatesTarget: any;
  declare startTarget: any;
  declare endTarget: any;
  declare distanceTarget: any;
  declare durationTarget: any;

  declare map: any;

  connect() {
    mapboxgl.accessToken = 'pk.eyJ1Ijoic21hcnRlZGl0b3JzIiwiYSI6ImNrYmduNjEyYzE3NWQyem5vNjJzeXE0d3MifQ.MX3vn11muEy1l4dhNLUv2w';
    // this.map = '';
  }

  public highlight(event: any) {
  //   event.preventDefault();

  //   this.map.flyTo({
  //     center: JSON.parse(event.currentTarget.dataset.coordinates),
  //     zoom: 15
  //   });
  }

  public reset() {
    // this.map.flyTo({
    //   center: 'this.startCoordinates',
    //   zoom: 8,
    //   pitch: 50,
    //   bearing: 15
    // });
  }
}