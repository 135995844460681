import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  connect() {
    StimulusReflex.register(this)
  }

  star(event: any) {
    event.preventDefault();
    
    // @ts-ignore
    this.stimulate('SmartCom#star!', event.target);
    location.reload();
  }

  flag(event: any) {
    event.preventDefault();
    // @ts-ignore
    this.stimulate('SmartCom#flag!', event.target);
    location.reload();
  }

  snooze(event: any) {
    event.preventDefault();
    
    // @ts-ignore
    this.stimulate('SmartCom#snooze!', event.target);
    location.reload();
  }

  move(event: any) {
    event.preventDefault();
    
    // @ts-ignore
    this.stimulate('SmartCom#move!', event.target);
    location.reload();
  }

  assign(event: any) {
    event.preventDefault();
    
    // @ts-ignore
    this.stimulate('SmartCom#assign!', event.target);
    setTimeout(() => {
      location.reload();
    }, 1000);
  }
}