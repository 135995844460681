// not working, used only in the smartie home working overview report
// no mapboxgl plugin
// Core
//
import { Controller } from 'stimulus';

// Plugins
//
import axios, { AxiosResponse } from 'axios';

declare var mapboxgl;

export default class extends Controller {
  static targets = ['map'];

  declare map: any;

  declare hasMapTarget: boolean;
  declare mapTarget: any;
  declare mapTargets: any[];

  connect() {
    mapboxgl.accessToken = 'pk.eyJ1Ijoic21hcnRlZGl0b3JzIiwiYSI6ImNrYmduNjEyYzE3NWQyem5vNjJzeXE0d3MifQ.MX3vn11muEy1l4dhNLUv2w';

    this.getCurrentCoordinates();
  }

  private async render(coordinates?: any[]) {
    this.map = new mapboxgl.Map({
      container: this.mapTarget,
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 8,
      pitch: 50,
      bearing: 15,
      center: coordinates
    });

    this.map.on('load', async () => {
      const response: AxiosResponse<any> = await axios.get(this.data.get('url')),
            points: any = [];

      await response.data.jobs.forEach((job: any) => {
        points.push(this.marker(job));

        // new mapboxgl.Marker()
        //   .setLngLat(this.marker(job).geometry.coordinates)
        //   .addTo(this.map);
        
        this.map.addSource('trees', {
          type: 'geojson',
          data: {
            features: points,
            type: 'FeatureCollection'
          }
        });

        this.map.addLayer({
          id: 'trees-heat',
          type: 'heatmap',
          source: 'trees',
          maxzoom: 15,
          paint: {
            // increase weight as diameter breast height increases
            'heatmap-weight': {
              property: 'dbh',
              type: 'exponential',
              stops: [
                [1, 0],
                [62, 1]
              ]
            },
            // increase intensity as zoom level increases
            'heatmap-intensity': {
              stops: [
                [11, 1],
                [15, 3]
              ]
            },
            // assign color values be applied to points depending on their density
            'heatmap-color': [
              'interpolate',
              ['linear'],
              ['heatmap-density'],
              0, 'rgba(236,222,239,0)',
              0.2, 'rgb(208,209,230)',
              0.4, 'rgb(166,189,219)',
              0.6, 'rgb(103,169,207)',
              0.8, 'rgb(28,144,153)'
            ],
            // increase radius as zoom increases
            'heatmap-radius': {
              stops: [
                [11, 15],
                [15, 20]
              ]
            },
            // decrease opacity to transition into the circle layer
            'heatmap-opacity': {
              default: 1,
              stops: [
                [14, 1],
                [15, 0]
              ]
            },
          }
        }, 'waterway-label');
      });

      this.map.addLayer({
        id: 'trees-point',
        type: 'circle',
        source: 'trees',
        minzoom: 14,
        paint: {
          // increase the radius of the circle as the zoom level and dbh value increases
          'circle-radius': {
            property: 'dbh',
            type: 'exponential',
            stops: [
              [{ zoom: 15, value: 1 }, 5],
              [{ zoom: 15, value: 62 }, 10],
              [{ zoom: 22, value: 1 }, 20],
              [{ zoom: 22, value: 62 }, 50],
            ]
          },
          'circle-color': {
            property: 'dbh',
            type: 'exponential',
            stops: [
              [0, 'rgba(236,222,239,0)'],
              [10, 'rgb(236,222,239)'],
              [20, 'rgb(208,209,230)'],
              [30, 'rgb(166,189,219)'],
              [40, 'rgb(103,169,207)'],
              [50, 'rgb(28,144,153)'],
              [60, 'rgb(1,108,89)']
            ]
          },
          'circle-stroke-color': 'white',
          'circle-stroke-width': 1,
          'circle-opacity': {
            stops: [
              [14, 0],
              [15, 1]
            ]
          }
        }
      }, 'waterway-label');
    });

  }

  private marker(data: any) {
    return {
      type: 'Feature',

      properties: {
        name: data.sgg_id,
        address: data.address.full_address
      },

      geometry: {
        type: 'Point',
        coordinates: [
          data.address.longitude,
          data.address.latitude
        ]
      }
    }
  }

  disconnect() { }

  private async getCurrentCoordinates() {
    const options: any = {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0
    };

    await navigator.geolocation.getCurrentPosition(
      (position: any) => {
        this.render([
          position.coords.longitude,
          position.coords.latitude
        ]);
      }, (error) => {
        this.render([0, 0]);
      }, options);
  }
}