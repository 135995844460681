// working
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import { addClass, removeClass, hasClass } from '../../helpers/dom-helper';

// Plugins
//
import PubSub from 'pubsub-js';
import moment from 'moment';
import tippy, { delegate } from 'tippy.js';

import Sortable, { MultiDrag, AutoScroll } from 'sortablejs/modular/sortable.core.esm.js';

Sortable.mount(new MultiDrag(), new AutoScroll());

export default class extends Controller {
  static targets = ['startsAtDateAndTime', 'legsCounter', 'leg', 'legsContainer', 'overallDistance', 'overallDuration'];

  declare token: any;

  declare startsAtDateAndTimeTarget: any;

  declare legsCounterTarget: any;

  declare legTarget: any;
  declare legTargets: any[];

  declare hasLegsContainerTarget: boolean;
  declare legsContainerTarget: any;
  declare legsContainerTargets: any[];
  
  declare hasOverallDistanceTarget: any;
  declare overallDistanceTarget: any;

  declare hasOverallDurationTarget: any;
  declare overallDurationTarget: any;

  declare route: any; // { <id>: [lang: float, long: float] }
  declare sortable: Sortable;

  connect() {
    
    // Create an EventListener for form changes in order to re-adjust the route
    // distance and duration
    //
    this.element.addEventListener('change', (event: any) => {
      if (event.type === 'change') {
        const input: any = event.target;

        if (input.id === 'route_scheduled_at') {
          const routedLeg: any = this.route[0];

          routedLeg.depart_at = moment(this.startsAtDateAndTimeTarget.value).toString();
          routedLeg.leg.querySelector(`[data-control='leg-departure-input']`).value = moment(routedLeg.depart_at).format();
          
          this.rebuildRoute();
        }
        
        if (input.type === 'number') { this.rebuildRoute() }
      }
    });

    this.token = PubSub.subscribe('route:calculated', (topic: string, route: any) => {
      this.adjustRouteAttributesAndGetDirections(route);
    });

    // Initializes the Sortable events to allow User to sort legs
    //
    // this.initializeSortable();
    //
    this.sortable = Sortable.create(this.legsContainerTarget, {
      group: "name",  // or { name: "...", pull: [true, false, 'clone', array], put: [true, false, array] }
      sort: true,  // sorting inside list
      delay: 0, // time in milliseconds to define when the sorting should start
      delayOnTouchOnly: false, // only delay if user is using touch
      touchStartThreshold: 0, // px, how many pixels the point should move before cancelling a delayed drag event
      disabled: false, // Disables the sortable if set to true.
      store: null,  // @see Store
      easing: "cubic-bezier(1, 0, 0, 1)", // Easing for animation. Defaults to null. See https://easings.net/ for examples.
      filter: ".ignore-elements",  // Selectors that do not lead to dragging (String or Function)
      preventOnFilter: true, // Call `event.preventDefault()` when triggered `filter`
    
      dataIdAttr: 'data-id',
    
      ghostClass: "sortable-ghost",  // Class name for the drop placeholder
      chosenClass: "sortable-chosen",  // Class name for the chosen item
      dragClass: "sortable-drag",  // Class name for the dragging item
      draggable: '.-sortable',
      handle: '.-sortable-handle',
    
      swapThreshold: 1, // Threshold of the swap zone
      invertSwap: false, // Will always use inverted swap zone if set to true
      invertedSwapThreshold: 1, // Threshold of the inverted swap zone (will be set to swapThreshold value by default)
      direction: 'horizontal', // Direction of Sortable (will be detected automatically if not given)
      animation: 150,
    
      forceFallback: false,  // ignore the HTML5 DnD behaviour and force the fallback to kick in
    
      fallbackClass: "sortable-fallback",  // Class name for the cloned DOM Element when using forceFallback
      fallbackOnBody: false,  // Appends the cloned DOM Element into the Document's Body
      fallbackTolerance: 0, // Specify in pixels how far the mouse should move before it's considered as a drag.
    
      dragoverBubble: false,
      removeCloneOnHide: true, // Remove the clone element when it is not showing, rather than just hiding it
      emptyInsertThreshold: 5, // px, distance mouse must be from empty sortable to insert drag element into it
    

      scroll: true, // Enable the plugin. Can be HTMLElement.
      // scrollFn: function(offsetX, offsetY, originalEvent, touchEvt, hoverTargetEl) { ... }, // if you have custom scrollbar scrollFn may be used for autoscrolling
      scrollSensitivity: 100, // px, how near the mouse must be to an edge to start scrolling.
      scrollSpeed: 10, // px, speed of the scrolling
      bubbleScroll: true, // apply autoscroll to all parent elements, allowing for easier movement

      multiDrag: true, // Enable the plugin
      selectedClass: "sortable-selected", // Class name for selected item

      onEnd: (event) => {
        setTimeout(() => { this.rebuildRoute() }, 100)
      }
    });
  }

  initialize() {
    this.route = {};

    // Create the initial Route, usually when the Route is being edited or the
    // the Route is initialized with pre-selected Jobs
    //
    if (this.legTargets.length > 2) {
      this.rebuildRoute();
    }
  }

  private async publishRouteDetails() {
    PubSub.publish('route:calculate', Object.values(this.route)
      .map((route: any) => {
        return {
          location: route.location,
          stopover: true
        }
      }));
  }

  private async adjustRouteAttributesAndGetDirections(legsResponse: any[]) {
    legsResponse.map((leg: any, index: number) => {

      // Define the previous leg in order to get the initial value of 'depart_at'
      // which sets the 'arrive_at' value on the next leg.
      //
      const previousRoutedLeg: any = this.route[index - 1],
                nextRoutedLeg: any = this.route[index + 1],
                    routedLeg: any = this.route[index];

      let adjust: any = 0;
      
      if (routedLeg.travel_time_adjust['type'] == 'minutes') {
        adjust = routedLeg.travel_time_adjust['amount'] * 60
      } else if (routedLeg.travel_time_adjust['type'] == 'hours') {
        adjust = routedLeg.travel_time_adjust['amount'] * 3600
      }

      let arrive, depart;
      
      routedLeg.duration = (leg.duration.value + adjust);
      routedLeg.distance = leg.distance.value;

      routedLeg.leg.querySelector(`[data-control='order']`).value = (index + 1);
      routedLeg.leg.querySelector(`[data-control='leg-duration-input']`).value = routedLeg.duration;
      routedLeg.leg.querySelector(`[data-control='leg-distance-input']`).value = routedLeg.distance;

      routedLeg.leg.querySelector(`[data-target='leg-duration']`).innerHTML = moment.utc(routedLeg.duration * 1000).format("H [hours] [and] m [minutes]");
      routedLeg.leg.querySelector(`[data-target='leg-distance']`).innerHTML = (routedLeg.distance * 0.000621371192).toFixed(2);

      arrive = moment(routedLeg.depart_at).add(routedLeg.duration, 'seconds').toString();

      if (nextRoutedLeg) {
        depart = moment(arrive).add(nextRoutedLeg.time_on_site_adjust['amount'], nextRoutedLeg.time_on_site_adjust['type']).toString();

        nextRoutedLeg.arrive_at = arrive;
        nextRoutedLeg.depart_at = depart;
      
        nextRoutedLeg.leg.querySelector(`[data-control='leg-arrival-input']`).value = moment(arrive).format();
        nextRoutedLeg.leg.querySelector(`[data-control='leg-departure-input']`).value = moment(depart).format();

        if (nextRoutedLeg.leg.querySelector(`[data-target='leg-arrival']`)) {
          nextRoutedLeg.leg.querySelector(`[data-target='leg-arrival']`).innerHTML = moment(arrive).format('h:mmA');
        }

        if (nextRoutedLeg.leg.querySelector(`[data-target='leg-departure']`)) {
          nextRoutedLeg.leg.querySelector(`[data-target='leg-departure']`).innerHTML = moment(depart).format('h:mmA');
        }
      }
    });

    let durations = [];
    Object.values(this.route).forEach((l: any) => durations.push(l.duration));

    let distances = [];
    Object.values(this.route).forEach((l: any) => distances.push(l.distance));

    this.overallDurationTarget.innerHTML = moment.utc(durations.reduce((a, b) => a + b) * 1000).format("HH [h] mm [m]")
    this.overallDistanceTarget.innerHTML = (distances.reduce((a, b) => a + b) * 0.000621371192).toFixed(1);
  }

  public async add(event: any) {
    event.preventDefault();

    const target: any = event.currentTarget,
          template: any = (document.querySelector(`template[id~="${ target.dataset.templateTarget }"]`).innerHTML as any)
      .replace(/TEMPLATE_ID/g, Math.floor(Math.random() * 20));

    addClass(target, '-state-hidden');

    this.legTargets[this.legTargets.length - 1].insertAdjacentHTML('beforebegin', template);
    this.rebuildRoute();

    // TODO: This should delegate to body in order to avoid calling tippy again.
    // For some reason it doesn't work at the moment. I need to investigate and
    // ensure it does work on newly added HTML elements.
    //
    // delegate('body', { target: '[data-template]' });
    //
    // @pacMakaveli 22nd June, 2020
    //
    tippy(document.querySelectorAll('[data-template]'), {
      interactive: true,
      hideOnClick: 'toggle',
      placement: 'bottom-end',
      animation: 'shift-toward',
      distance: 16,
      theme: 'dropdown',

      content(reference: any) {
        return document.getElementById(reference.getAttribute('data-template')).innerHTML;
      }
    });
  }

  public async remove(event: any) {
    event.preventDefault();

    const target: any = event.currentTarget,
          parent: any = document.getElementById(target.dataset.parent);

    if (window.confirm('Are you sure?')) {
      const state: any = document.getElementById(target.dataset.state),
            input: any = document.getElementById(target.dataset.input);

      if (!state) {
        if (!window.confirm("This Job is not listed. If you remove it now you won't be able to add it again to this Route.")) {
          return;
        }
      }

      addClass(parent, 'animated');
      addClass(parent, 'hinge');

      if (input) {
        input.checked = true;

        setTimeout(() => {
          if (state) {
            removeClass(state, '-state-hidden');
          }

          addClass(parent, '-state-hidden');

          this.rebuildRoute();
        }, 2000);
      }
    }
  }

  private rebuildRoute() {
    this.legTargets
      .filter((leg: any, index: any) => {
        delete this.route[index];
        return !hasClass(leg, '-state-hidden');
      })
      
      .map(async (leg: any, i: number) => {
        const coordinates: any = JSON.parse(leg.dataset.coordinates);

        let depart_at_date_and_time: any = 0;

        if (i == 0) {
          depart_at_date_and_time = this.startsAtDateAndTimeTarget.value;
        }

        this.route[i] = {
          leg: leg,

          location: {
            lng: coordinates[0],
            lat: coordinates[1]
          },

          arrive_at: moment(depart_at_date_and_time).toString(),
          depart_at: moment(depart_at_date_and_time).toString(),

          travel_time_adjust: {
            amount: leg.querySelector(`[data-control='leg-travel-adjust-amount']`).value,
            type: leg.querySelector(`[data-control='leg-travel-adjust-type']`).value
          },

          time_on_site_adjust: {
            amount: leg.querySelector(`[data-control='leg-duration-adjust-amount']`).value,
            type: leg.querySelector(`[data-control='leg-duration-adjust-type']`).value
          },

          duration: 0,
          distance: 0
        }
      });

    this.legsCounterTarget.innerHTML = Object.keys(this.route).length;
    this.publishRouteDetails();
  }
}