// not used
// Core
//
import { Controller } from 'stimulus';

// Plugins
//
import Tribute from 'tributejs';
import Trix from 'trix';
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = ['field'];

  declare fieldTarget: any;

  declare editor: any;
  declare tribute: any;

  connect() {
    this.editor = this.fieldTarget.editor;
    this.initializeTribute();
  }

  private initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: 'email',
      values: this.users
    });

    this.tribute.attach(this.fieldTarget);
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this);
    this.fieldTarget.addEventListener('tribute-replaced', this.replaced);
  }

  private async users(text: string, callback: Function) {
    const response: AxiosResponse<any> = await axios.get('http://localhost:3001/mentions', {
      params: {
        format: 'json',
        query: text
      }
    });

    const users: Array<any> = response.data;

    if (users.length > 0 && response.status === 200) {
      return callback(users);
    } else {
      return callback([]);
    }
  }

  private replaced(event: any) {
    const mention: any = event.detail.item.original,
          attachment: any = new Trix.Attachment({
            sgid: mention.attachable_sgid,
            content: mention.content
          });

    this.editor.insertAttachment(attachment);
    // this.editor.insertString(" ");
  }

  private _pasteHtml(html: any, startPos: any, endPos: any) {
    let position = this.editor.getPosition();
    this.editor.setSelectedRange([position - endPos, position]);
    this.editor.deleteInDirection("backward");
  }

  disconnect() {
    this.tribute.detach();
  }
}