// Core
//
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['feedback'];
  static values = {
    url: String
  };

  declare feedbackTarget: HTMLElement;
  declare urlValue: string;

  countdown: any;

  initialize() { }

  connect() {
    const that = this;
    let seconds: number = (parseInt(this.feedbackTarget.textContent) || 5);

    this.countdown = setInterval(() => {
      seconds--;
      that.feedbackTarget.textContent = seconds.toString();

      if (seconds <= 0) {
        clearInterval(this.countdown);
        window.location.href = this.urlValue;
      };
    }, 1000);
  }

  disconnect() {
    clearInterval(this.countdown);
  }
}