// working
// Core
//
import { Controller } from 'stimulus';

// Plugins
//
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = ['surveyQuestionsSelect'];

  declare surveyQuestionsSelectTarget: any;
  declare hasSurveyQuestionsSelectTarget: boolean;

  public async get(event: any) {
    if (this.hasSurveyQuestionsSelectTarget) {
      const request: AxiosResponse<any> = await axios.get(this.data.get('url'), {
        params: {
          survey_type: event.srcElement.value
        }
      });

      if (request.status === 200) {
        this.updateSelectField(request.data);
      } else {
        // TODO
      }
    }
  }

  updateSelectField(data) {
    this.surveyQuestionsSelectTarget.innerHTML = "";
    data.forEach((question) => {
      const option = document.createElement("option");
      option.value = question.id;
      option.innerHTML = question.label;
      this.surveyQuestionsSelectTarget.appendChild(option);
    });
  }
}