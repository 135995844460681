// not used
// Core
// 
import { Controller } from 'stimulus';

// Helpers
// 
import { addClass, removeClass } from '../../helpers/dom-helper';

// Plugins
// 
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = [''];

  connect() { }

  public async track(event: any) {
  }
}