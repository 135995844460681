// working
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import { addClass, removeClass } from '../../helpers/dom-helper';
import _debounce from 'lodash/debounce';
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = ['userEmail', 'contactFields', 'locationType', 'userEmailFields'];

  declare userEmailTarget: HTMLInputElement;
  declare contactFieldsTarget: HTMLElement;
  declare locationTypeTarget: HTMLSelectElement;
  // declare userEmailFieldsTarget: HTMLInputElement;
  declare delayedCheck: any;

  initialize() {
    this.delayedCheck = _debounce((query: string) => { this.check(query) }, 1000);
  }

  connect() { }

  private async check(event: any) {

    const email = this.userEmailTarget.value;

    if (email === '') {
      this.hideContactFields();
    } else {
      const response: AxiosResponse<any> = await axios.get('/admin/users/find_by_email', {
        params: {
          format: 'json',
          query: email
        }
      });

      if (response.status === 200) {
        response.data ? this.hideContactFields() : this.showContactFields();
      }
    }
  }

  public hideContactFields() {
    addClass(this.contactFieldsTarget, '-state-hidden');
  }

  public showContactFields() {
    removeClass(this.contactFieldsTarget, '-state-hidden');
  }

  // public hideEmailField() {
  //   addClass(this.userEmailFieldsTarget, '-state-hidden');
  // }

  // public showEmailField() {
  //   removeClass(this.userEmailFieldsTarget, '-state-hidden');
  // }

  // public toggleUserFields(event) {
  //   if (this.locationTypeTarget.value !== '') {
  //     this.showEmailField();
  //   } else {
  //     this.hideEmailField();
  //     this.hideContactFields();
  //     this.userEmailTarget.value = '';
  //   }
  // }
}