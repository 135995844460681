import React from 'react';
import PubSub from 'pubsub-js';


class GroupToggle extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked
    };
  }

  componentDidMount() {
    this.TOKEN = PubSub.subscribe(this.props.type + "-" + this.props.group, (msg, data) => {
      this.setState({ checked: data.checked });
      if(this.props.permissions_role_id && !data.checked) { this.setState({ destroy: true }); }
    });
  }

  componentWillUnMount() {
    PubSub.unsubscribe(this.TOKEN);
  }

  toggleChecked() {
    let newCheckedState = !this.state.checked;
    let destroy = !newCheckedState;

    this.setState({ checked: newCheckedState, destroy: destroy }, function () {
      if (this.props.master) {
        PubSub.publish(this.props.type + "-" + this.props.group, {
          checked: newCheckedState, destroy: destroy
        });
      }
    }.bind(this));
  }

  dummyChange() {

  }

  render() {
    if(this.props.group == 'Contractor'){
    }
    let destroy_tag = null;
    if (this.props.permissions_role_id) {
      destroy_tag = <input type="hidden" name={this.props.name + '[_destroy]'} value={this.state.destroy === true} />
    }

    let id_tag = null;
    if (this.props.permissions_role_id) {
      id_tag = <input type="hidden" name={this.props.name + '[id]'} value={this.props.permissions_role_id} />
    }

    return (
      <div className="cell-sm-auto align-center">
        {id_tag}
        <input type="checkbox" name={this.props.name + '[permission_id]'} id={this.props.identifier} value={this.props.value} className="toggleable" onClick={this.toggleChecked.bind(this)} onChange={this.dummyChange.bind(this)} checked={this.state.checked} />
        <label htmlFor={this.props.identifier} ></label>
        { destroy_tag }
      </div>
    );
  }

  // render() {
  //   return (
  //     <div className="cell-sm-auto align-center">
  //       <input type="checkbox" name={this.props.name} id={this.props.identifier} value={this.props.value} className="hidden-checkbox" onChange={this.dummyChange.bind(this)} checked={this.state.checked} />
  //       <label htmlFor={this.props.identifier} className="fake-checkbox nudge-right" style={{ 'verticalAlign': 'sub' }}>
  //         <span className="material-icons checked" onClick={this.toggleChecked.bind(this)}>toggle_on</span>
  //         <span className="material-icons not-checked" onClick={this.toggleChecked.bind(this)}>toggle_off</span>
  //       </label>
  //     </div>
  //   );
  // }

}

export default GroupToggle;

