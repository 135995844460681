import { Controller } from 'stimulus'
import SignaturePad from 'signature_pad';

export default class extends Controller {
  static targets = ['container', 'input', 'output', 'save', 'clear'];

  pad: any;

  declare containerTarget;
  declare inputTarget;
  declare outputTarget;

  connect () {
    // @ts-ignore
    if (!this.hasInputTarget) return;

    this.pad = new SignaturePad(this.inputTarget, {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      penColor: 'rgb(0, 0, 0)'
    });

    if (this.outputTarget.value) {
      this.pad.fromDataURL(this.outputTarget.value);
    }

    this.resize();
  }
  
  clear(event: any) {
    event.preventDefault();

    this.pad.clear();
    this.outputTarget.value = '';
  }

  save(event: any) {
    event.preventDefault();
    
    this.outputTarget.value = this.pad.toDataURL('image/png');
  }

  resize() {
    const canvas = this.inputTarget.getContext('2d');

    canvas.width  = this.containerTarget.offsetWidth;
    this.inputTarget.setAttribute('width', (this.containerTarget.offsetWidth - 4));

    this.pad.clear();
  }

  disconnect () {
    this.pad.off();
  }
}