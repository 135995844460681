// not working, not working on develop
// Core
//
import { Controller } from 'stimulus';

// Plugins
//
import PubSub from 'pubsub-js';
import Dexie from 'dexie';

// Helpers
//
// @ts-ignore
import Interaction from '../../../plugins/interaction.ts';

export default class extends Controller {
  static targets: Array<string> = ['counter'];

  declare counterTarget: HTMLElement;
  declare hasCounterTarget: boolean;

  declare token: any;
  declare cache: any;

  declare counter: number;

  initialize() { this.counter = 0; }

  async connect() {
    this.cache = await new Dexie('sggs');
    
    if (this.hasCounterTarget) {
      await this.cache.open();

      this.counter = await this.cache.table('actions').count();
      this.counterTarget.innerHTML = this.counter.toString();
    } else {

      // Persist the payload across pagination or page refreshes
      //
      this.cache.version(1).stores({
        actions: '++id, uuid'
      });

      // Check if there are any Actions in the database, if there are, open the Actions popup
      //
      this.showPopout(await this.cache.table('actions').count());
      
      this.token = PubSub.subscribe('sggs.actions', (topic: string, payload: any) => {
        this.tx(payload);
      });
    }
  }

  public tx(payload: any) {
    this.cache.transaction('rw', this.cache.actions, async () => {
      let currentCounter: number = await this.cache.table('actions').count();

      if (payload.selected) {
        currentCounter++;
        this.cache.actions.add({ uuid: payload.uuid });
      } else {
        currentCounter--;
        this.cache.actions.where('uuid').equals(payload.uuid).delete();
      }

      this.showPopout(currentCounter);
      document.getElementById('counter').innerHTML = currentCounter.toString();
    });
  }

  private showPopout(counter: number) {
    const popout: any = new Interaction();
    
    if (Boolean(counter)) {
      if (!popout.isOpen) {
        popout.open(null, document.querySelector('template#actions'));
      }
    } else {
      // if (popout.isOpen) { popout.close(); }
    }
  }

  disconnect() {
    PubSub.unsubscribe(this.token);
    this.cache.close();
  }
}