// working
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import { addClass, removeClass, hasClass } from '../../helpers/dom-helper';
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = ['destroyMembership', 'membershipComponent', 'client', 'roles'];

  declare destroyMembershipTarget: HTMLInputElement;
  declare membershipComponentTarget: HTMLScriptElement;
  declare clientTarget: HTMLInputElement;
  declare rolesTarget: HTMLInputElement;

  intialize() { }

  remove(event){
    this.destroyMembershipTarget.value = '1';
    addClass(this.membershipComponentTarget, '-state-hidden');
  }

  public async updateRoles(event){
    this.rolesTarget.innerHTML = '';

    const request: AxiosResponse<any> = await axios.get(`/clients/${this.clientTarget.value}/admin/roles`, {
    });

    if (request.status === 200) {
      request.data.forEach((role) => {
        const option = document.createElement("option");
        option.value = role.id;
        option.innerHTML = role.label;
        this.rolesTarget.appendChild(option);
      });
    }
  }
}