import CableReady from 'cable_ready';
import consumer from './consumer';

consumer.subscriptions.create('SmartComChannel', {
  connected() {
    console.log(this)
    console.log('channel SmartComChannel connected')
  },

  disconnected() { },

  received(data) {  
    console.log('received', data)
    if (data.cableReady) {
      CableReady.perform(data.operations);
    }
  }
});
