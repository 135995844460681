// not used
// Core
//
import { Controller } from 'stimulus';

// Plugins
//
import PubSub from 'pubsub-js';

export default class extends Controller {
  static targets = [];

  connect() {
    this.element.addEventListener('ajax:success', (event: any) => {
      const [response, status, xhr] = event.detail;

      PubSub.publish('legs.addition', {
        html: response,
        order: 0,
        data: null
      });
    });
  }

  disconnect() { }
}