// working
// Controller for the SlimSelect view component using Slim Select JS plugin
// Could be tidied up using Values instead of data attributes, but values
// stopped working half way through this and I can't get them to work again 

// Core
//
import { Controller } from 'stimulus';

// Plugins
//
import SlimSelect from 'slim-select';
import _debounce from 'lodash/debounce';
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {

  static targets = ['slim']

  declare slimTarget: any;
  declare url: string;
  declare slimId: string;
  declare displayAttribute: string;
  declare limit: string;
  declare select: any;
  declare delayedSearch: any;

  initialize(){
    this.delayedSearch = _debounce((query: string, callback: Function) => { this.search(query, callback) }, 500);
  }

  connect() {
    this.url = this.slimTarget.dataset.url;
    this.displayAttribute = this.slimTarget.dataset.displayAttribute;
    this.slimId = this.slimTarget.dataset.slimId;
    this.limit = this.slimTarget.dataset.limit;
    this.registerSelect();
  }

  registerSelect() {
    if(this.url) {
      this.select = new SlimSelect({ 
        select: `#${this.slimId}`,
        searchingText: 'Searching...',
        ajax: (search, callback) => {       
          const options = this.delayedSearch(search, callback);
        }
      })
    } else {
      this.select = new SlimSelect({ 
        select: `#${this.slimId}`,
        searchingText: 'Searching...'
      })
    }
  }

  public async search(query: string, callback: Function) {
    const response: AxiosResponse<any> = await axios.get(this.url, {
      params: {
        format: 'json',
        query: query,
        per_page: this.limit
      }
    });

    if (response.status === 200 && response.data) {
      let options = []
      for (let i = 0; i < response.data.length; i++) {
        options.push({ value: response.data[i].id, text: response.data[i][this.displayAttribute] })
      }
      callback(options);
    }
  }
}