// working
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import { removeClass } from '../helpers/dom-helper';

// Plugins
//
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = ['file', 'url', 'image', 'placeholder'];

  declare fileTarget:        HTMLInputElement;
  declare urlTarget:         HTMLInputElement;
  declare imageTarget:       HTMLImageElement;
  declare imageTargets:      HTMLImageElement[];
  declare placeholderTarget: HTMLElement;

  declare hasUrlTarget: boolean;
  declare hasPlaceholderTarget: boolean;

  connect() { }

  public async preview(event: any) {
    if (event.target.type == 'file') {
      if (this.fileTarget.files && this.fileTarget.files[0]) {
        const file: FileReader = new FileReader();

        file.onload = (event: any) => {
          this.previewImage(event.target.result)
        };

        // Reset the URL input if there is a value when selecting an image from
        // a local source
        //
        if (this.hasUrlTarget) {
          this.urlTarget.value = '';
        }

        // Get the image file and pass it to the onload function
        //
        file.readAsDataURL(this.fileTarget.files[0]);
      }
    } else {
      this.previewImage(event.target.value);
    }

    if (await this.data.get('inline')) {
      this.processImage(event);
    }
  }

  private previewImage(image: any) {

    // Remove the placeholder
    //
    if (this.hasPlaceholderTarget) {
      this.placeholderTarget.remove();
    }

    // Remove the class that makes the image hidden
    //
    removeClass(this.imageTarget, '-state-hidden');

    // Set the image URL to the base64 result of the FileReader
    //
    this.imageTarget.setAttribute('src', image);
  }

  private processImage(event: any) {
    // TODO: Perform AJAX
    event.currentTarget.form.submit();
  }
}