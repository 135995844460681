// working
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import { hasClass, addClass, removeClass } from '../../helpers/dom-helper';

// Plugins
//
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = ['usersContainer', 'membersContainer'];

  declare url: string;

  declare usersContainerTarget: any;
  declare membersContainerTarget: any;

  connect() { }

  public async create(event: any) {
    const target: any = event.target,
          id: any     = target.value,
          url: string = target.dataset.url;

    const response: AxiosResponse = await axios
      .post(event.target.dataset.url, {
        membership: {
          user_id: id,
          status: 'confirmed'
        }
      });

    if (response.status === 200) {
      this.switch(id, response.data, this.membersContainerTarget);
    } else {
      // Notification
    }
  }

  public async destroy(event: any) {
    const target: any = event.target,
          id: any     = target.value,
          url: string = target.dataset.url;

    const response: AxiosResponse = await axios.delete(url);

    if (response.status === 200) {
      this.switch(id, response.data, this.usersContainerTarget);
    } else {
      // Notification
    }
  }

  private switch(id: any, html: any, container: any) {
    const div: any = document.createElement('div');
          div.innerHTML = html;

    document.getElementById(`membership-${ id }`).remove();

    container.appendChild(div);
  }
}