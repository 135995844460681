import React from 'react';

const ABILITIES = [
  {
    cans: ["view Projects"],
    cants: ["view Reports and Routes", "view/edit Projects", "view/edit Reports and Routes", "manage Users", "manage Workspaces"]
  },
  {
    cans: ["view Projects", "view Reports and Routes"],
    cants: ["view/edit Projects", "view/edit Reports and Routes", "manage Users", "manage Workspaces"]
  },
  {
    cans: ["view Projects", "view Reports and Routes"],
    cants: ["view/edit Projects", "view/edit Reports and Routes", "manage Users", "manage Workspaces"]
  },
  {
    cans: ["view Projects", "view Reports and Routes", "view/edit Projects"],
    cants: ["view/edit Reports and Routes", "manage Users", "manage Workspaces"]
  },
  {
    cans: ["view Projects", "view Reports and Routes", "view/edit Projects", "view/edit Reports and Routes"],
    cants: ["manage Users", "manage Workspaces"]
  },
  {
    cans: ["view Projects", "view Reports and Routes", "view/edit Projects", "view/edit Reports and Routes", "manage Users"],
    cants: ["manage Workspaces"]
  },
  {
    cans: [],
    cants: []
  }
];

class SliderComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPresetValue: (this.props.currentPresetValue || 0)
    };
  }

  getCurrentKey() {
    return this.props.values[this.state.currentPresetValue].key;
  }

  getCurrentTitle() {
    return this.props.values[this.state.currentPresetValue].title;
  }

  getCurrentDescription() {
    return this.props.values[this.state.currentPresetValue].description;
  }

  renderTicks() {
    if (this.props.displayTicks) {

      const ticks = [],
            ticksLength = this.props.values.length;

      this.props.values.forEach(tick => {
        const val = (this.props.values.indexOf(tick) * 100) / (ticksLength - 1);
        // const position = `calc(${ val }% + (${ 10 - val * 0.15 }px))`;
        const position = `${ val }%`;

        ticks.push(<span key={ 'tick-' + tick.id } className="-margin-y_4 slider-tick"  style={{ left: position }}></span>);
        ticks.push(
          <p key={ 'tick-txt-' + tick.id } className="-line-10 -x400 -gray -darker slider-milestone-value" style={{ left: position }}>
            { tick.label }
          </p>
        );
      });

      return (
        <div className="slider-ticks">
          { ticks }
        </div>
      );
    } else {
      return null;
    }
  }

  renderCurrentSelectionInformation() {
    if (this.props.displayPresetDetails) {
      let detailsClass = 'slider-details -margin-top';

      if (this.props.displayTicks) {
        detailsClass += ' with-ticks';
      }

      return (
        <div className={detailsClass}>
          <h3 className='-line-10 -blue'>{ this.getCurrentTitle() }</h3>
          <p className='-line-10 -smaller -margin-top_4 -gray -darker'>{ this.getCurrentDescription() }</p>

          <div className='row -margin-top_2'>
            <div className='cell-sm'>
              <ul className='-padding_reset -margin_reset -margin-left_2'>
                {
                  ABILITIES[this.state.currentPresetValue].cans.map(function(ability, index) {
                    return (
                      <li key={ 'can-' + index } className='-line-14 -x400 -smaller -green'>{ 'Can ' + ability }</li>
                    );
                  })
                }
              </ul>
            </div>

            <div className='cell-sm'>
              <ul className='-padding_reset -margin_reset -margin-left_2'>
                {
                  ABILITIES[this.state.currentPresetValue].cants.map(function(ability, index) {
                    return (
                      <li key={ 'cant-' + index } className='-line-14 -x400 -smaller -red'>{ 'Can\'t ' + ability }</li>
                    );
                  })
                }
              </ul>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderAdvancedMode() {

    // This should check the key or an attribute that tells us the preset
    // doesn't require an Advanced mode
    //
    if (this.props.additionalPermissionsURL && (ABILITIES[6].cans.length === 0 && ABILITIES[6].cants.length === 0)) {
      return (
        <div className='row align-center -margin-top'>
          <div className='cell-sm-auto'>
            <p className='-line-10 -smaller -x300 -gray -darker'>Add or Remove additional permissions for this Role.</p>

            <div className='row align-center -margin-top_3'>
              <div className='cell-sm-auto'>
                <a href={ this.props.additionalPermissionsURL + `?role=` + this.getCurrentKey() } className='btn -blue -x3' data-action='actions#show' data-position='-bottom' data-backdrop='true'>
                  Advanced Mode
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  handleChange(e) {
    this.setState({ currentPresetValue: e.target.value });
  }

  render() {
    return (
      <div className='cell-sm-12'>
        <div className='row pill -gray -round -padding_2 slider-container'>
          <input type='range' className='slider' orient='vertical' min='0' max={ this.props.values.length - 1 } id='customRange2' onChange={ this.handleChange.bind(this) } defaultValue={ this.state.currentPresetValue } />
          { this.renderTicks() }
        </div>

        { this.renderCurrentSelectionInformation() }
        { this.renderAdvancedMode() }
      </div>
    );
  }
}

SliderComponent.defaultProps = {
  displayTicks: true,
  displayPresetDetails: true
}

export default SliderComponent;