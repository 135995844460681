// not used
// Core
// 
import { Controller } from 'stimulus';

// Helpers
// 
import { addClass, removeClass } from '../../helpers/dom-helper';

// Plugins
// 
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = [''];

  connect() { }

  public async track(event: any) {
    event.currentTarget();

    const url: string = '',
          response: AxiosResponse = await axios.get(url);

    if (response) {

    } else {
      console.error(response)
    }
  }
}