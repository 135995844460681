import consumer from './consumer';

consumer.subscriptions.create('HealthChannel', {
  connected() {
    this.send({ message: 'Client is live!' })
  },

  disconnected() { },

  received(data) {
    console.log(data);
  }
});