// working
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import { addClass, removeClass } from '../../helpers/dom-helper';

// Plugins
//
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = ['form', 'slasContainer', 'customDatesContainer', 'selected'];

  declare formTarget: any;
  declare slasContainerTarget: any;
  declare customDatesContainerTarget: any;
  declare selectedTarget: any;
  declare hasSelectedTarget: boolean;

  connect() {
    if (!this.hasSelectedTarget) {
      this.getSLAs();
    }
  }

  public async getSLAs() {
    this.disableAndHideCustomSLAs(false);

    const params = { },
    elements = this.formTarget.querySelectorAll('input, select, textarea');

    for (let i = 0; i < elements.length; ++i) {
      const element: any = elements[i],
      name: string = element.name,
      value: string = element.value;

			if (name) { params[name] = value }
    }

    const response: AxiosResponse = await axios.get(this.data.get('slasUrl'), {
      params: params
    });

    this.slasContainerTarget.innerHTML = response.data;
  }

  public async setSLA(event: any) {
    event.preventDefault();
    const response: AxiosResponse = await axios.get(event.currentTarget.href);
    this.slasContainerTarget.innerHTML = response.data;
    this.disableAndHideCustomSLAs(true);
  }

  disableAndHideCustomSLAs(disable) {
    if (disable) {
      addClass(this.customDatesContainerTarget, '-state-hidden');
    } else {
      removeClass(this.customDatesContainerTarget, '-state-hidden');
    }
    const inputElements: HTMLInputElement[] = Array.from(this.customDatesContainerTarget.getElementsByTagName('INPUT'))
    inputElements.forEach((el: HTMLInputElement) => {
      el.disabled = disable;
    })
  }
}