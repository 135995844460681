import React from 'react';

class TimelineOperatorField extends React.Component {

  selectOptions = {};

  constructor(props) {
    super(props);

    this.state = {
      operators: this.props.value || []
    }
  }

  addOperator() {
    let newOperator = { quantity: 1, operator_id: (this.props.availableOperators[0].id || "")};

    let operators = this.state.operators;
    operators.push(newOperator);

    this.setState({ operators: operators }, this.triggerChange);
  }

  updateOperator(value, field, index) {
    let operators = this.state.operators;
    let operator = operators[index];

    operator[field] = value;
    operators[index] = operator;

    this.setState({ operators: operators }, this.triggerChange);
  }

  removeOperator(index) {
    let operators = this.state.operators;
    let operator = operators[index];

    if (operator.id) {
      this.updateOperator("1", "destroy", index);
    } else {
      operators.splice(index, 1);
      this.setState({ operators: operators }, this.triggerChange);
    }

    
  }

  triggerChange() {
    this.props.updateCallback(this.state.operators, this.props.field);
  }


  render() {
    return (
      <div className="field compact">
        <div className="row">
          <div className="cell-sm"></div>
          <div className="cell-sm-auto -padding">
            <div className="btn -margin-top_3 -blue" style={{ padding: '6px 10px', fontSize: '2em', fontWeight: 'bold', lineHeight: '.8em' }} onClick={this.addOperator.bind(this)}>+</div>
          </div>
          <div className="cell-sm"></div>
        </div>
        <div>

          {this.props.value.map(function(operator, index){
              if (operator.destroy) {
                return (
                  <span key={index}>
                    <input type="hidden" name={this.props.name + "[id]"} value={operator.id} />
                    <input type="hidden" name={this.props.name + "[_destroy]"} value="1" />
                  </span>
                )

              } else {
                return (
                <div key={index} className="row">
                  {operator.id &&
                    <input type="hidden" name={this.props.name + "[id]"} value={operator.id} />
                  }
                  <div className="cell-sm-9 -padding-right_4">
                    <select className="form-control -padding_3 -margin-top_3" name={this.props.name + "[operator_id]"} value={operator.operator_id} onChange={(e) => this.updateOperator(e.target.value, 'operator_id', index)}>
                      {this.props.availableOperators.map(function(o, i){
                        return (
                          <option key={i} value={o.id}>{o.name}</option> 
                        )
                      })}
                    </select>
                  </div>

                  <div className="cell-sm-2">
                    <input className="form-control -padding_3 -margin-top_3 -text-center" style={{ backgroundColor: 'white' }} type="number" name={this.props.name + "[quantity]"} value={operator.quantity} onChange={(e) => this.updateOperator(e.target.value, 'quantity', index)} />
                  </div>

                  <div className="cell-sm align-center align-middle -margin-top_3">
                    <div className="btn -red" style={{ fontSize: '1.4em', fontWeight: 'bold', lineHeight: '1em', padding: '0px 9px 3px' }} onClick={() => this.removeOperator(index)}>-</div>
                  </div>
                </div>
              )
            }
          }.bind(this))}
        </div>
        <label>Operators Required</label>
      </div>
    );
  }

}

export default TimelineOperatorField;