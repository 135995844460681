// working
// Core
//
import { Controller } from 'stimulus';

// Plugins
//
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = ['address', 'container', 'counter', 'exclusionsContainer'];

  declare radius: number;

  declare addressTarget: any;
  declare addressTargets: any[];
  declare hasAddressTarget: boolean;

  declare containerTarget: any;
  declare exclusionsContainerTarget: any;

  declare hasCounterTarget: boolean;
  declare counterTarget: any;

  declare lastSuggestionURL: string;

  initialize() { this.radius = 5; }
  connect() { }

  public radiusChange(event: any) {
    this.radius = parseInt(event.currentTarget.value)
    this.get(null);
  }

  public async get(event: MouseEvent) {
    let url: string;

    // Clear all the previous suggestions.
    //
    this.containerTarget.innerHTML = '';

    // Go over the form legs and extract all the job IDs that have already been
    // added in order to exclude them from the nearby search
    //
    const exclusions: string[] = [];

    // Array.from(this.exclusionsContainerTarget.children).forEach((child: HTMLElement) => {

    //   // => @data-ownerable-id='job-#'
    //   //
    //   const id: string = child.id.split('_')[1];

    //   exclusions.push(id);
    // })

    if (event) {

      // @ts-ignore
      const target: HTMLAnchorElement = event.currentTarget;

      url = target.href;
      this.lastSuggestionURL = url;
  
      exclusions.push(target.id.split('_')[2]);

    } else { url = this.lastSuggestionURL }

    const params: { exclusions: string[], radius: number } = {
      exclusions: exclusions,
      radius: this.radius
    }

    // REVIEW: Ideally this shouldn't be fetched through an additional request.
    // Gets the suggestions data that can feedback the user.
    //
    const meta: AxiosResponse<any> = await axios.get(url, {
      data: {}, /* forces axios to send data in JSON format https://github.com/axios/axios/issues/86 */
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json'
      },
      params: params
    });

    this.counterTarget.innerHTML = meta.data.total;
    this.addressTarget.innerHTML = `Found <strong>${ meta.data.total }</strong> suggestions within a <strong>${ this.radius }</strong> miles radius of <strong>${ meta.data.address }<strong>`;

    const response: AxiosResponse<any> = await axios.get(url, { params: params });

    this.containerTarget.innerHTML = response.data;
  }

  disconnect() { }
}