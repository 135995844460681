// not used
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import { addClass } from '../../helpers/dom-helper';
import _templateSettings from 'lodash/templateSettings';
import _template from 'lodash/template';

// Plugins
//
import PubSub from 'pubsub-js';

export default class extends Controller {
  static targets = ['container'];

  declare token: any;

  declare containerTarget: any;

  connect() {
    _templateSettings.interpolate = /\{\{\=(.+?)\}\}/g;
    _templateSettings.evaluate = /\{\{(.+?)\}\}/g;

    this.token = PubSub.subscribe('legs.addition', (topic: string, leg: any) => {
      this.insert(leg.html, leg.order, leg.data);
    });
  }

  public insert(html: any, order: number, data?: any) {
    const template: any = _template(html);
    const node: HTMLElement = document.createElement('div');
          node.innerHTML = template({
            timestamp: Date.now(),
            data: data,
            order: order
          });

    addClass(node, 'sortable-source');
    addClass(node, 'pill');
    addClass(node, '-white');

    const lastChild: any = (this.containerTarget.children.length === 0 ? this.containerTarget.lastChild : this.containerTarget.lastChild.nextSibling);

    this.containerTarget.insertBefore(node, lastChild);
  }

  disconnect() {
    PubSub.unsubscribe(this.token);
  }
}