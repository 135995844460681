// Tibby.JS - A Navigation Tab plugin which allows you to have multiple
//           nabs ( navs + tabs, get it? :D ) on a page..
//           Tibby excels in setting history when navigation across multiple
//           nabs and allowing you to set your own URLs.
import { hasClass, addClass, removeClass, findParent } from '../helpers/dom-helper';
import { TableHTMLAttributes } from 'react';

export default class Tibby {
  declare options: any;
  
  declare currentWindowHash: any;

  constructor(customOptions: any = {}) {
    this.options = {};
    this.currentWindowHash = {};

    this.options = {
      tabsDefaultItem:      'data-hashtabber-default' || customOptions.tabsDefaultItem,
      tabsID:               'data-tabs-id'            || customOptions.tabsID,
      tabsActiveClass:      '.active'                 || customOptions.tabsActiveClass,
      
      tabsNavContainer:     '[data-tabs]'             || customOptions.tabsNavContainer,
      tabsNavItem:          '[data-tabs-item]'        || customOptions.tabsNavItem,
      tabsNavURL:           '[data-tab]'              || customOptions.tabsNavURL,
      
      tabsContentContainer: '[data-tabs-content]'     || customOptions.tabsContentContainer,
      tabsContentItem:      '[data-tabs-pane]'        || customOptions.tabsContentItem
    }
    this.checkCurrentWindowLocation();
    this.run();
  }

  private set(tab: string, target: HTMLAnchorElement | any /* also put any as we don't want to restrict the element to just anchors */) {
    try {
      const contents:    any = document.querySelectorAll(`[data-tabs-content='${ tab }']`),
            selectedTab: any = target.dataset.tab,
            parent:      any = document.querySelector(`[data-tabs-item='${ selectedTab }']`);

      contents.forEach((content: any) => {
        const panes: any = content.querySelectorAll(this.options.tabsContentItem);

        panes.forEach((pane: any) => {

          if (pane.dataset.tabsPane === selectedTab) {
            this.currentWindowHash[tab]['data-tabs-item'] = parent.dataset.tabsItem;
            this.currentWindowHash[tab]['data-tab'] = selectedTab;
            this.currentWindowHash[tab]['data-tabs-pane'] = pane.dataset.tabsPane;
            // The tab-pane, usually the div that holds the HTML
            //
            addClass(pane, 'active');

            // The tab-item, usually li > a
            //
            addClass(parent, 'active');
            addClass(target, 'active');
            
            this.setWindowLocation();
            return;
          } else {
            // The tab-pane, usually the div that holds the HTML
            //
            removeClass(pane, 'active');
            
            // The tab-item, usually li > a
            //
            removeClass(document.querySelectorAll(`[data-tabs-item='${ pane.dataset.tabsPane }']`)[0], 'active');
          }
        });
      });
    } catch (e) {
      console.log("Anchor in URL but no tab-content found");
    }
  }

  public run() {
    const tabs: any = document.querySelectorAll(this.options.tabsNavContainer);

    if (tabs.length > 0) {
      tabs.forEach((tab: any) => {
        const name: any = tab.dataset.tabs;

        tab.querySelectorAll('[data-tab]').forEach((trigger: any) => {
          trigger.addEventListener('click', (event: any) => {
            event.preventDefault();

            this.currentWindowHash[name] = {
              'data-tabs-content': name,
              'data-tabs-item': '',
              'data-tab': '',
              'data-tabs-pane': ''
            };

            this.set(name, event.currentTarget);
          })
        });
      });
    }
  }

  private checkCurrentWindowLocation() {
    let hash: string = window.location.hash;

    if (hash) {
      hash = hash.replace('#', '');

      (hash.split(',') || []).forEach((location: any) => {
        const tibby: any[] = location.split('=');

        const tab: string = tibby[0],
              target: any = document.querySelectorAll(`[data-tab='${ tibby[1] }']`)[0];

        this.currentWindowHash[tab] = {}
        this.currentWindowHash[tab]['data-tabs-content'] = tab;
        this.currentWindowHash[tab]['data-tab'] = tibby[1];

        this.set(tab, target);
      });
    }
  }

  private setWindowLocation() {
    const encodedHash = [];

    Object.values(this.currentWindowHash).forEach((key) => {
      encodedHash.push(`${ key['data-tabs-content'] }=${ key['data-tab'] }`)
    })

    return window.location.hash = String(encodedHash);

    // const a: any = document.querySelectorAll(this.options.tabsNavURL);

    // a.forEach((tabTrigger) => {
    //   tabTrigger.addEventListener('click', (event) => {
    //     event.preventDefault();
                
    //     this.setTab(tabTrigger)
    //   })
    // })
    
    // let that = this;

    // window.onhashchange = function () {
    //   that.checkCurrentLocationHash(that.options);
    // };

    // return true;
  }
  
  checkCurrentLocationHash(options: any) {
    // try {
    //   let navigationTabs: any = document.querySelectorAll(options.tabsNavContainer);
    //   const xx = {};

    //   if (window.location.hash != '') {   
    //     let bb = window.location.hash
    //       .replace('#', '')
    //       .split(',')

    //     bb.forEach((yy) => {
    //       let zz = yy.split('=');

    //       xx[zz[0]] = {
    //         tab: zz[0],
    //         item: zz[1]
    //       }
    //     })
    //   }

    //   navigationTabs.forEach((tabs: any) => {
    //     const activeTabNavigation: string = tabs.dataset.tabsId;
    //     const activeTabNavigationItem: string = tabs
    //       .querySelector(`${ options.tabsNavItem }${ options.tabsActiveClass }`)
    //       .querySelector(options.tabsNavURL).hash.replace('#', '');

    //     this.currentWindowHash[activeTabNavigation] = {}
    //     this.currentWindowHash[activeTabNavigation]['tab'] = activeTabNavigation;
    //     this.currentWindowHash[activeTabNavigation]['item'] = activeTabNavigationItem;
        
    //     if (window.location.hash == '') {
    //       // this.setTab(document.querySelector(`a[href='#${ activeTabNavigationItem }']`))
    //     } else {
    //       try {
    //         this.setTab(document.querySelector(`a[href='#${ xx[activeTabNavigation]['item'] }']`))
    //       } catch(e) {
    //         console.log(e)
    //       }
    //     }
    //   });
      
      // this.setWindowLocationHash();
    // } catch(e) {
    //   console.info('Tibby.JS failed to initialize properly!')
    //   console.info('This is usually because the HTML structure has not been set properly. Please review the documentation on how to set Tibby.JS')
    //   console.error(e)      
    // }
  }

  setWindowLocationHash() {
    // const encodedHash = [];
    
    // let o: any = Object;

    // o.values(this.currentWindowHash).forEach((key) => {
    //   encodedHash.push(`${ key['tab'] }=${ key['item']}`)
    // })

    // return window.location.hash = String(encodedHash);
  }

  setTab(selectedTab) {
    // const selectedTabParentTabItem = findParent(selectedTab, '.tab-item'),
    //       selectedTabParentTabs = findParent(selectedTab, '.tabs');

    // this.currentWindowHash[selectedTabParentTabs.dataset.tabsId]['item'] = selectedTab.hash.replace('#', '')

    // const selectedTabParentTabsChildren = selectedTabParentTabs
    //   .querySelectorAll(this.options.tabsNavItem);

    // addClass(selectedTabParentTabs, 'active');

    // selectedTabParentTabsChildren.forEach((children) => {
    //   if (selectedTabParentTabItem == children) {
    //     // addClass(selectedTab, 'active')
    //     addClass(children, 'active')

    //     let cc: any = document
    //       .querySelectorAll(`[data-tabs-content][data-tabs-id='${ selectedTabParentTabs.dataset.tabsId }']`);

    //     cc.forEach((cs) => {
    //       let c = cs.querySelectorAll(this.options.tabsContentItem);
    //       c.forEach((ee) => {
    //         if (ee == document.getElementById(selectedTab.hash.replace('#', ''))) {
    //           addClass(document.getElementById(selectedTab.hash.replace('#', '')), 'active')
    //         } else {
    //           removeClass(ee, 'active')
    //         }
    //       })
    //     })
    //   } else {
    //     removeClass(selectedTab, 'active')
    //     removeClass(children, 'active')
    //   }
    // })

    // this.setWindowLocationHash();
  }
}