// partly working - think navigator.geolocation.getCurrentPosition is broken, but also not working on develop. MarkerClusterer not defined
// Core
//
import { Controller } from 'stimulus';

// Plugins
//
import axios, { AxiosResponse } from 'axios';
import PubSub from 'pubsub-js';

declare var google;
declare var MarkerClusterer;

export default class extends Controller {
  static targets = ['map', 'coordinates'];

  declare token: any;
  declare map: any;

  declare mapTarget: any;
  declare coordinatesTarget: any;

  declare directionsService: any;
  declare directionsRenderer: any;

  connect() {
    this.getCurrentCoordinatesAndRenderMap();

    this.token = PubSub.subscribe('route:calculate', (topic: string, route: any) => {
      this.calculateAndRenderDirections(route);
    });
  }

  private async render(coordinates: { lng: number, lat: number }) {
    this.map = new google.maps.Map(this.mapTarget, {
      zoom: 6,
      center: coordinates
    });

    this.directionsService = new google.maps.DirectionsService;
    this.directionsRenderer = new google.maps.DirectionsRenderer;


    if (this.data.get('url')) {
      this.populateMapAndClusterify();
    }
  }

  public async populateMapAndClusterify(clustered: boolean = true) {
    const response: AxiosResponse<any> = await axios.get(this.data.get('url'));

    if (response) {
      const markers: any = response.data.forEach((job: any) => {
        return new google.maps.Marker({
          position: {
            lng: job.address.longitude,
            lat: job.address.latitude
          },

          label: `SWJ #${ job.id }`
        });
      });

      if (clustered) {
        new MarkerClusterer(this.map, markers, { imagePath: 'http://localhost:1337/assets/m' });
      }
    }
  }

  public async calculateAndRenderDirections(waypoints: any) {
    if (this.directionsRenderer) {
      this.directionsRenderer.setMap(null);
    }

    if (this.map) {
      this.directionsRenderer.setMap(this.map);

      const start: any = waypoints.shift(),
            end: any = waypoints.pop();

      this.directionsService.route({
        origin:      start.location,
        destination: end.location,

        waypoints: waypoints,
        optimizeWaypoints: false,
        travelMode: 'DRIVING'

      }, (response: any, status: string) => {
        if (status === 'OK') {
          this.directionsRenderer.setDirections(response);

          PubSub.publish('route:calculated', response.routes[0].legs)
          // PubSub.publish('route.leg.details', route.legs[route.legs.length - 1]);
        } else {
          window.alert(`Couldn't get Directions due to: ${ status }`);
        }
      });
    } else {
      window.alert("Couldn't load the map. Please try again!");
    }
  }

  disconnect() {
    PubSub.unsubscribe(this.token);
  }

  private async getCurrentCoordinatesAndRenderMap() {
    const options: { enableHighAccuracy: boolean, timeout: number, maximumAge: number } = {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0
    };

    await navigator.geolocation.getCurrentPosition((position: any) => {
      this.render({
        lng: position.coords.longitude,
        lat: position.coords.latitude
      });
    }, (error: any) => {
      window.alert(`We couldn't get your current position in order to center the map. ${ error }`)
      this.render({ lng: 0, lat: 0 });

    }, options);
  }
}