// Working
import { Controller } from 'stimulus'

// Helpers
//
// @ts-ignore
import Interaction from '../../plugins/interaction.ts';

export default class extends Controller {
  closeModal(){
    new Interaction().close();
  }
}