import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
  connect () {
    console.log('connected')
    console.log(this)
    StimulusReflex.register(this);
  }

  /* Application wide lifecycle methods.
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "ExampleReflex#demo"
   *
   *   error - error message from the server
   */

  beforeReflex (element, reflex) {
  }

  reflexSuccess (element, reflex, error) {
  }

  reflexError (element, reflex, error) {
  }

  afterReflex (element, reflex) {
    // document.body.classList.remove('wait')
  }
}
