// not used
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import { addClass, removeClass } from '../../helpers/dom-helper';
import _templateSettings from 'lodash/templateSettings';
import _template from 'lodash/template';

// Plugins
//
import axios, { AxiosResponse } from 'axios';
import TingleModal from 'tingle.js';
import SlimSelect from 'slim-select';

export default class extends Controller {
  static targets = ['name'];

  declare nameTarget: any;

  connect() {
    _templateSettings.interpolate = /\{\{\=(.+?)\}\}/g;
    _templateSettings.evaluate = /\{\{(.+?)\}\}/g;
  }

  public async get(event: any) {
    event.preventDefault();

    const target: any = event.currentTarget,
          url: string = target.href,
          response: AxiosResponse<any> = await axios.get(url, {
            params: {
              attributeable: event.currentTarget.dataset.attributeable
            }
          });

    if (response) {
      const modal: TingleModal = new TingleModal.modal({
        beforeOpen: () => {
          const selectors: any = document.querySelectorAll('.select');

          selectors.forEach((selector: HTMLSelectElement) => {
            new SlimSelect({
              select: selector
            });
          });

          modal.modal.setAttribute('data-attributeable', target.dataset.attributeable);
        },

        beforeClose: () => {
          return true;
        },

        onClose: () => {
          modal.destroy();
        }
      });

      await modal.setContent(response.data);
      await modal.open();
    } else {
      console.error(response);
    }
  }

  public async setNewAttribute(event: any) {
    const target: any = document.querySelectorAll('div[data-target="actions.container"]')[0],
          template: any = _template(document.getElementById('attributeTemplate').innerHTML),
          element: any = document.createElement('div');

    element.innerHTML = template({
      id: new Date().getTime(),
      field: event.target.value,
      name: this.nameTarget.value
    });

    target.insertAdjacentElement('beforeend', element);
  }

  public async remove(event: any) {
    event.preventDefault();

    const target: any = event.currentTarget;

    if (window.confirm('Are you sure?')) {
      addClass(this.parent(target), 'animated');
      addClass(this.parent(target), 'hinge');

      const checkbox: any = document.getElementById(`checkbox-${ target.dataset.parent }`);
            checkbox.checked = true;

      setTimeout(() => { addClass(this.parent(target), '-state-hidden'); }, 2000);
    }
  }

  private parent(target: any): HTMLElement {
    return document.getElementById(target.dataset.parent);
  }
}