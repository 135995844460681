import React from 'react';

class NumberField extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      value: props.value || 0
    };
  }

  incrementNumber() {
    let value = parseFloat(this.state.value);
    this.setState({ value: value += 0.5 }, function(){
      this.triggerChange();
    }.bind(this));
  }

  decrementNumber() {
    let value = parseFloat(this.state.value);
    this.setState({ value: Math.max(value -= 0.5, 0) }, function(){
      this.triggerChange();
    }.bind(this));
  }

  manualChange(e) {
    this.setState({ value: e.target.value }, function(){
      this.triggerChange();
    }.bind(this));
  }

  triggerChange() {
    this.props.updateCallback(this.state.value, this.props.name);
  }

  render() {
    return (
      <div className="row">
        <div className="cell-sm-2 -text-center pill -rounder -red -margin-right_3 -padding-y_4 -padding-x_3" onClick={this.decrementNumber.bind(this)}>
          <span className="-color -white" style={{ fontSize: '1.2em', fontWeight: '400', cursor: 'pointer'}}>V</span>
        </div>
        <input className="cell-sm form-control -padding_3" name={this.props.fieldName} min="0" step="0.5" type="number" style={{backgroundColor: 'white', textAlign: 'center'}} value={this.state.value} onChange={this.manualChange.bind(this)} name={this.props.fieldName} />
        <div className="cell-sm-2 -text-center pill -rounder -green -margin-left_3 -padding-y_4 -padding-x_3" onClick={this.incrementNumber.bind(this)}>
          <span className="-color -white" style={{ fontSize: '1.2em', fontWeight: '400', cursor: 'pointer', transform: 'rotate(180deg)'}}>V</span>
        </div>
      </div>
    );
  }

}

export default NumberField;