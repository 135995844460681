// show and replace methods works, haven't been able to hit any others
// Core
//
import { Controller } from 'stimulus';

// Plugins
//
import axios, { AxiosResponse } from 'axios';
// @ts-ignore
import Tibby from '../plugins/tibby.ts';

// Helpers
//
// @ts-ignore
import Interaction from '../plugins/interaction.ts';
import { hasClass, addClass, removeClass } from '../helpers/dom-helper';
import tippy, { delegate, followCursor } from 'tippy.js';

export default class extends Controller {
  static targets = ['container'];

  declare containerTarget: any;

  connect() { }

  public async show(event: any) {
    new Interaction().show(event);
  }

  public edit(event: any) {
    new Interaction().modal(event);
  }

  public new(event: any) {
    new Interaction().modal(event);
  }

  public modal(event: any) {
    new Interaction().modal(event);
  }

  public popout(event: any, content?: any) {
    new Interaction().open(event, content);
  }

  public async replace(event: any) {
    event.preventDefault();

    const target: any = event.currentTarget,
          url: string = target.href;

    const response: AxiosResponse<any> =  await axios.get(url);
    document.getElementById(target.dataset.container).innerHTML = response.data;

    try {
      addClass(document.getElementById(`${ target.dataset.container }-parent`), 'replaced');
    } catch(e) {

    }

    tippy(document.querySelectorAll('[data-template]'), {
      interactive: true,
      trigger: 'click',
      hideOnClick: 'toggle',
      placement: 'bottom-end',
      animation: 'shift-toward',
      distance: 16,
      theme: 'dropdown',

      content(reference: any) {
        try { reference.preventDefault(); } catch(e) { }

        const template = document.getElementById(reference.getAttribute('data-template'));
              // container = document.createElement('div'),
              // node = document.importNode(template.content, true);

        // container.appendChild(node);

        // return container;
        try {
          return template.innerHTML;
        } catch(e) {
          console.warn(e)
        }
      }
    });

    new Tibby();
  }

  public async add(event: any) {
    event.preventDefault();

    const target: any = event.currentTarget,
          time: any = new Date().getTime(),
          regexp: any = new RegExp(target.dataset.id, 'g')

    this.containerTarget.insertAdjacentHTML('beforeend', target.dataset.fields.replace(regexp, time));
  }

  public async remove(event: any) {
    event.preventDefault();

    const target: any = event.currentTarget;
    console.log(target);

    if (window.confirm('Are you sure?')) {
      addClass(this.parent(target), 'animated');
      addClass(this.parent(target), 'hinge');

      const checkbox: any = document.getElementById(`checkbox-${ target.dataset.parent }`);

      if (checkbox) {
        console.log('else');

        checkbox.checked = true;

        setTimeout(() => {
          addClass(this.parent(target), '-state-hidden');
        }, 2000);
      } else {
        console.log('something');
        this.parent(target).remove();
      }
    }
  }
  private parent(target: any): HTMLElement {
    return document.getElementById(target.dataset.parent);
  }
}