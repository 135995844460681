// not used
// Core
//
import { Controller } from 'stimulus';

// Plugins
//

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export default class extends Controller {
  static targets = ['chart'];

  declare chartTarget: any;

  connect() {

    am4core.useTheme(am4themes_animated);
    let chart = am4core.create(this.chartTarget, am4charts.XYChart);

    chart.data = [{
    "country": "1st April",
    "visits": 2025
    }, {
    "country": "2nd April",
    "visits": 1882
    }, {
    "country": "3rd April",
    "visits": 1809
    }, {
    "country": "4th April",
    "visits": 1322
    }, {
    "country": "5th April",
    "visits": 1122
    }, {
    "country": "...",
    "visits": 1114
    }, {
    "country": "6st May",
    "visits": 984
    }, {
    "country": "7th May",
    "visits": 711
    }, {
    "country": "8th May",
    "visits": 665
    }, {
    "country": "9th May",
    "visits": 580
    }, {
    "country": "10th May",
    "visits": 443
    }, {
    "country": "11th May",
    "visits": 441
    }];

    chart.padding(40, 40, 40, 40);

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "country";
    categoryAxis.renderer.minGridDistance = 60;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.disabled = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.extraMax = 0.1;
    //valueAxis.rangeChangeEasing = am4core.ease.linear;
    //valueAxis.rangeChangeDuration = 1500;

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "country";
    series.dataFields.valueY = "visits";
    series.tooltipText = "{valueY.value}"
    series.columns.template.strokeOpacity = 0;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.cornerRadiusTopLeft = 10;
    //series.interpolationDuration = 1500;
    //series.interpolationEasing = am4core.ease.linear;
    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.verticalCenter = "bottom";
    labelBullet.label.dy = -10;
    labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.')}";

    chart.zoomOutButton.disabled = true;

    // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
    series.columns.template.adapter.add("fill", function (fill, target) {
    return chart.colors.getIndex(target.dataItem.index);
    });

    // setInterval(function () {
    // am4core.array.each(chart.data, function (item) {
    //   item.visits += Math.round(Math.random() * 200 - 100);
    //   item.visits = Math.abs(item.visits);
    // })
    // chart.invalidateRawData();
    // }, 2000)

    // categoryAxis.sortBySeries = series;

  }
}