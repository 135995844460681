// not used
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import { addClass, removeClass } from '../../helpers/dom-helper';

// Plugins
//
import * as moment from 'moment';
// @ts-ignore
import Calendar from 'tui-calendar';
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = ['calendar'];

  declare calendarTarget: any;

  connect() {
    var calendar = new Calendar(this.calendarTarget, {
      defaultView: 'month',
      taskView: true,
      template: {
        monthGridHeader: function(model) {
          var date = new Date(model.date);
          var template = '<span class="tui-full-calendar-weekday-grid-date">' + date.getDate() + '</span>';

          return template;
        }
      }
    });

    calendar.createSchedules([
      {
          id: '1',
          calendarId: '1',
          title: 'my schedule',
          category: 'time',
          dueDateClass: '',
          start: '2019-02-13T02:30:00+09:00',
          end: '2019-02-14T02:30:00+09:00',
      },
      {
          id: '2',
          calendarId: '1',
          title: 'second schedule',
          category: 'time',
          dueDateClass: '',
          start: '2019-02-13T02:30:00+09:00',
          end: '2019-02-24T02:30:00+09:00',
          isReadOnly: true    // schedule is read-only
      }
  ]);

    // new Calendar(this.calendarTarget, {
    //   defaultView: 'month',
    //   useCreationPopup: true,
    //   useDetailPopup: true,
    //   // calendars: CalendarList,
    //   template: {
    //     milestone: (model) => {
    //       return '<span class="calendar-font-icon ic-milestone-b"></span> <span style="background-color: ' + model.bgColor + '">' + model.title + '</span>';
    //     },

    //     allday: (schedule) => {
    //       return this.getTimeTemplate(schedule, true);
    //     },

    //     time: (schedule) => {
    //       return this.getTimeTemplate(schedule, false);
    //     }
    //   }
    // });
  }

  getTimeTemplate(schedule, isAllDay) {
    var html = [];
    var start = moment(schedule.start.toUTCString());

    if (!isAllDay) {
      html.push('<strong>' + start.format('HH:mm') + '</strong> ');
    }
    
    if (schedule.isPrivate) {
      html.push('<span class="calendar-font-icon ic-lock-b"></span>');
      html.push(' Private');
    } else {
      if (schedule.isReadOnly) {
        html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
      } else if (schedule.recurrenceRule) {
        html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
      } else if (schedule.attendees.length) {
        html.push('<span class="calendar-font-icon ic-user-b"></span>');
      } else if (schedule.location) {
        html.push('<span class="calendar-font-icon ic-location-b"></span>');
      }
      
      html.push(' ' + schedule.title);
    }

    return html.join('');
  }
}