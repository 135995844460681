// working? Postcode request doesn't return, but doesn't on develop either
// Core
//
import { Controller } from 'stimulus';

// Helpers
//
import { hasClass, addClass, removeClass } from '../helpers/dom-helper';
import _compact from 'lodash/compact';
import _findIndex from 'lodash/findIndex';
import _debounce from 'lodash/debounce';

// Plugins
//
import axios, { AxiosResponse } from 'axios';
import { contains } from '@amcharts/amcharts4/.internal/core/utils/DOM';

export default class extends Controller {
  static targets = ['loader', 'latitude', 'longitude', 'postCode', 'addresses'];

  declare addresses: any[];

  declare cors;
  //cors: string = 'https://cors-anywhere.herokuapp.com/';

  // https://getaddress.io/
  //
  // @admin: awrhgcpzaE6q2S3akNonVg26098
  // Needs renewing every 30 days
  // 
  // another: f_1Gk8HEOEeIWgeLu9op0g26098
  // anotheradmin: awrhgcpzaE6q2S3akNonVg26098

  // another2: yBZ8uu1Ly0yfmov0XEtbXw26099
  // anotheradmin: NjuDlOOD90CDHihIw4evdg26099

  // another3: rHkRLLTniUirU9vG-A0feA26100
  // anotheradmin: 1UThqyY680ycE2P7MQyA0g26100

  // another4: SvHSk10VE0So2pEgZF0fWg26101
  // anotheradmin: BxQf8WYbdEqc6eDt7VifBg26101

  // another5: PWOc2x2SukidL3I3PzRkSg26102
  // anotheradmin: lW8Ld9LGg0y6k8AVBqEDzQ26102

  // another6: KvYIsZEi_0aJIw7-PmAHMw26103
  // anotheradmin: Sg9n78Uyh0GgpMuWRHJzug26103

  declare apiKey: string;
  // apiKeys: any = ['neg38ZBKQES1aHERm4HaLQ25973', 'f_1Gk8HEOEeIWgeLu9op0g26098', 'yBZ8uu1Ly0yfmov0XEtbXw26099', 'rHkRLLTniUirU9vG-A0feA26100', 'SvHSk10VE0So2pEgZF0fWg26101', 'PWOc2x2SukidL3I3PzRkSg26102', 'KvYIsZEi_0aJIw7-PmAHMw26103'];

  declare loaderTarget: HTMLElement;
  declare addressesTarget: HTMLSelectElement;
  declare latitudeTarget: HTMLInputElement;
  declare longitudeTarget: HTMLInputElement;
  declare postCodeTarget: HTMLInputElement;

  declare delayedCheck: any;


  initialize() {
    this.delayedCheck = _debounce((query: string) => { this.check(query) }, 1000);
    this.apiKey = 'f_1Gk8HEOEeIWgeLu9op0g26098';
    this.cors = 'https://cors-anywhere.herokuapp.com/';
  }

  connect() { }

  private async check(event: any) {
    removeClass(this.loaderTarget, '-state-hidden');

    // const postCode = event.target.value,
    //       url: string = `https://api.postcodes.io/postcodes/${postCode}/validate`, //`https://api.postcodes.io/postcodes/${ postCode }/validate`,
    //       response: AxiosResponse = await axios.get(url);

    const postCode = (event.target.value || "").replace(/\s+/g, ''),
          url: string = `/admin/locations/get_address?mode=check&postcode=${postCode}`,
          response: AxiosResponse = await axios.get(url);

    console.log(response);

    if (response.status === 200 && response.data.status === 200 && response.data) {
      this.get(postCode);
    } else {
      this.feedback('Not a valid Post Code!');
    }
  }

  private async get(postCode: string, house?: any) {
    // Left this here in case we need to renable it - quick fix for api key issues basically
    //const index = Math.floor(Math.random() * Math.floor(this.apiKeys.length));
    //const randomKey = this.apiKeys[index];

    // const url: string = `https://api.getAddress.io/find/${ postCode }${ house ? '/' + house : '' }?api-key=${ this.apiKey }&expand=true`,
    //       response: AxiosResponse<any> = await axios.get(url);

    const url: string = `/admin/locations/get_address?mode=get&postcode=${postCode}${ house ? '&house=' + house : '' }`,
          response: AxiosResponse<any> = await axios.get(url);

    if (response.status === 200) {
      this.addresses = response.data.addresses;

      // Special case for these values as they're not part of the Address
      // response
      //
      this.latitudeTarget.value = response.data.latitude;
      this.longitudeTarget.value = response.data.longitude;
      this.postCodeTarget.value = response.data.postcode;

      const options: HTMLOptionsCollection = this.addressesTarget.options;
            options.length = 0;

      if (this.addresses.length === 1) {
        const address: any = this.addresses[0];

        this.feedback(_compact(address.formatted_address).join(', '));
        this.fill(address);
      } else {
        this.feedback('Select an Address')
        this.addresses.forEach((address: any) => {
          options[(_findIndex(this.addresses, address) + 1)] = new Option(_compact(address.formatted_address).join(', ').slice(0, 50), (_findIndex(this.addresses, address) + 1));
          // new Option(_compact(address.formatted_address).join(', '), (_findIndex(this.addresses, address) + 1))
        });
      }
    } else {
      this.feedback(response.status)
    }
  }

  public fill(event: any) {
    const address: any = (event.type === 'change' ? this.addresses[event.target.value - 1] : event);

    Object.keys(address).forEach((key: string) => {
      const input: HTMLInputElement = document.querySelector(`[data-fill=${ key }]`);

      if (input) { input.value = address[key] }
    });
  }

  // Sets the first Option of the addressesTarget(select) to show whatever
  // message we want
  //
  private feedback(message: any) {
    this.addressesTarget.options[0] = new Option(message);
    addClass(this.loaderTarget, '-state-hidden');
  }
}