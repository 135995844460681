// Core
//
import { Controller } from 'stimulus';

// // Helpers
// //
import { addClass, removeClass } from '../../helpers/dom-helper';

export default class extends Controller {

  static targets = ['table', 'arrowDown', 'arrowRight'];

  declare tableTarget: any;
  declare arrowDownTarget: any;
  declare arrowRightTarget: any;

  connect(){
    console.log('sggs--journals connected')
  }

  toggle(event){
    event.preventDefault();
    if(this.tableTarget.classList.contains('-state-hidden')) {
      removeClass(this.tableTarget, '-state-hidden')
      addClass(this.arrowRightTarget, '-state-hidden')
      removeClass(this.arrowDownTarget, '-state-hidden')
    } else {
      addClass(this.tableTarget, '-state-hidden')
      addClass(this.arrowDownTarget, '-state-hidden')
      removeClass(this.arrowRightTarget, '-state-hidden')
    }
  }
}