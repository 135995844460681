// working
// Core
//
import { Controller } from 'stimulus';

// Plugins
//
import axios, { AxiosResponse } from 'axios';
import PubSub from 'pubsub-js';

export default class extends Controller {
  static targets = ['surveyQuestionsContainer'];

  declare surveyQuestionsContainerTarget: any;
  declare hasSurveyQuestionsContainerTarget: boolean;

  public async toggleQuestion(event: any) {
    const question = event.target;
    PubSub.publish('checkpoint.items', {
      toggleItemId: question.dataset.id,
      label: question.dataset.label
    });
  }

  public async get(event: any) {
    console.log('get')
    if (this.hasSurveyQuestionsContainerTarget) {
      const surveyID: number = parseInt(event.srcElement.value);

      const request: AxiosResponse<any> = await axios.get(this.data.get('url'), {
        params: {
          survey_id: surveyID
        }
      });

      if (request.status === 200) {
        this.surveyQuestionsContainerTarget.innerHTML = request.data;
        
        PubSub.publish('checkpoint.items', {
          population: JSON.parse(document.getElementById('population').getAttribute('data-items'))
        });

      } else {
        // TODO
      }
    }
  }
}